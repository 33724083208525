var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _vm.permission.saveSymptom
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.addRegionClick({}, { parentId: 0 }, 1)
                            },
                          },
                        },
                        [_vm._v("添加症状")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "regionContent" },
            [
              _c("el-tree", {
                attrs: {
                  data: _vm.tableData,
                  "node-key": "symptomId",
                  props: _vm.defaultProps,
                  "default-expanded-keys": _vm.defaultExpandIds,
                },
                on: {
                  "node-expand": _vm.handleNodeExpand,
                  "node-collapse": _vm.handleNodeCollapse,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c(
                        "span",
                        {
                          staticClass: "custom-tree-node",
                          staticStyle: { width: "100%" },
                          on: {
                            mouseenter: function ($event) {
                              return _vm.mouseenter(data)
                            },
                            mouseleave: function ($event) {
                              return _vm.mouseleave(data)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: node.label != "",
                                  expression: "node.label != ''",
                                },
                              ],
                            },
                            [_vm._v(" " + _vm._s(node.label) + " ")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    data.del && !_vm.showEdit[data.symptomId],
                                  expression:
                                    "data.del && !showEdit[data.symptomId]",
                                },
                              ],
                              staticClass: "button",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.parentId > 0,
                                      expression: "data.parentId > 0",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() => _vm.rowContent(data)).apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 症状说明 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        data.parentId > 0 &&
                                        _vm.permission.symptomGalleryList,
                                      expression:
                                        "data.parentId > 0 && permission.symptomGalleryList",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() =>
                                        _vm.symptomGalleryHandle(data)).apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 症状图集 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        data.parentId > 0 &&
                                        _vm.permission.symptomCompleteList,
                                      expression:
                                        "data.parentId > 0 && permission.symptomCompleteList",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() =>
                                        _vm.symptomCompleteHandle(data)).apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 症状关系 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.permission.saveSymptom,
                                      expression: "permission.saveSymptom",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() =>
                                        _vm.addRegionClick(
                                          node,
                                          data,
                                          1
                                        )).apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" 添加同级 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        data.parentId == 0 &&
                                        _vm.permission.saveSymptom,
                                      expression:
                                        "data.parentId == 0 && permission.saveSymptom",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() =>
                                        _vm.addRegionClick(
                                          node,
                                          data,
                                          2
                                        )).apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" 添加子级 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.permission.updateSymptom,
                                      expression: "permission.updateSymptom",
                                    },
                                  ],
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() =>
                                        _vm.editbankclassgetype(
                                          node,
                                          data
                                        )).apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.permission.removeSymptom,
                                      expression: "permission.removeSymptom",
                                    },
                                  ],
                                  ref: `popover-${data.symptomId}`,
                                  attrs: {
                                    title: "您确定要删除么？",
                                    iconColor: "red",
                                  },
                                  on: {
                                    confirm: () =>
                                      _vm.removeknowledgetype(node, data),
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.closePopover.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.showTypeTitle,
            visible: _vm.addSameLevelRegion,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSameLevelRegion = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addRegion",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.addRegion,
                rules: _vm.addRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "症状名称", prop: "symptomName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请填写症状名称" },
                    model: {
                      value: _vm.addRegion.symptomName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRegion, "symptomName", $$v)
                      },
                      expression: "addRegion.symptomName",
                    },
                  }),
                ],
                1
              ),
              _vm.addRegion.parentId == 0 && _vm.showType == 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "二级症状名称", prop: "secondLevelName" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请填写二级症状名称",
                        },
                        model: {
                          value: _vm.addRegion.secondLevelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRegion, "secondLevelName", $$v)
                          },
                          expression: "addRegion.secondLevelName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addRegion.parentId > 0 || _vm.showType == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "症状说明", prop: "symptomExplain" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          type: "textarea",
                          maxlength: "255",
                          "prefix-icon": "el-icon-user",
                          placeholder: "请填写症状说明",
                        },
                        model: {
                          value: _vm.addRegion.symptomExplain,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRegion, "symptomExplain", $$v)
                          },
                          expression: "addRegion.symptomExplain",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.addRegion.isShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRegion, "isShow", $$v)
                      },
                      expression: "addRegion.isShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "orderWeight" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写权重" },
                    model: {
                      value: _vm.addRegion.orderWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRegion, "orderWeight", $$v)
                      },
                      expression: "addRegion.orderWeight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetAddOrEditData } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    type: "primary",
                    loading: _vm.addSameRegionLoading,
                  },
                  on: { click: _vm.onAddSameRegionSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "症状示例图集",
            visible: _vm.dialogLookGalleryVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookGalleryVisible = $event
            },
          },
        },
        [
          _vm.dialogLookGalleryVisible
            ? _c("SymptomGalleryList", {
                attrs: { symptomId: _vm.selectSymptomId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "症状完善信息关系",
            visible: _vm.dialogSymptomCompleteVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSymptomCompleteVisible = $event
            },
          },
        },
        [
          _vm.dialogSymptomCompleteVisible
            ? _c("SymptomCompleteList", {
                attrs: { symptomId: _vm.selectSymptomId },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }