<template>
  <div class="container">
    <div class="page-container">
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        :row-key="'galleryId'"
        v-loading="showLoading"
        :height="tableHeight"
      >
        <el-table-column prop="galleryId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="图片" min-width="70">
          <template slot-scope="scope">
            <el-image
              style="max-width: 40px; max-height: 40px"
              class="table-td-thumb"
              :src="scope.row.thumbImg"
              :preview-src-list="[scope.row.bigImg]"
              fit="cover"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import beautyManager from '@/request/beautyManager.js'
export default {
  mixins: [getList],
  props: ["recordId"],
  data() {
    return {
      requst: {
        module: beautyManager,
        url: "consultationRecordGalleryInfoList",
        addUrl: "",
        updateUrl: "",
      },
      query: {
        recordId: this.recordId,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
