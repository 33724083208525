<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item v-if="sendUserId>0">
                    <el-input type="text" placeholder="请输入名称" v-model="query.nickName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select size="small" v-model="query.sendUserType" placeholder="请选择发送人类型" clearable>
                        <el-option v-for="item in sendUserTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveLogMessage && sendUserId==0" @click="onAdd">添加
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'messageId'" v-loading="showLoading"
                :height="tableHeight" >
                <!-- <el-table-column type="expand">
                    <template slot-scope="props">
                        <div>
                            <h4>消息图集：</h4>
                            <el-image style="margin-left:50px;width:100px;height:100px" class="table-td-thumb" :src="props.row.headImg"
                            :preview-src-list="[props.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <el-image style="margin-left:50px;width:100px;height:100px" class="table-td-thumb" :src="props.row.headImg"
                            :preview-src-list="[props.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <el-image style="margin-left:50px;width:100px;height:100px" class="table-td-thumb" :src="props.row.headImg"
                            :preview-src-list="[props.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        </div>
                        
                    </template>
                </el-table-column> -->
                <el-table-column prop="messageId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="sendUserType" label="发送人类型" width="100" align="center">
                    <template slot-scope="scope">
                        {{scope.row.sendUserType | getStateName(sendUserTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column label="用户头像" width="90" align="center" v-if="sendUserId>0">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.headImg"
                            :preview-src-list="[scope.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" width="120" align="center">
                </el-table-column>
                <el-table-column prop="content" label="内容" align="center">
                    <!-- <template slot-scope="scope">
                        <el-link type="primary" @click="rowContent(scope.row)">详情</el-link>
                    </template> -->
                </el-table-column>
                <el-table-column label="消息图集" width="100" align="center">
                    <template slot-scope="scope">
                        <span @click="showGalleryHandle(scope.row)" v-if="!scope.row.videoImp.length">
                            <el-link type="primary">查看图集</el-link>
                        </span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column label="视频封面" width="90" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.videoImp"
                            :preview-src-list="[scope.row.videoImp]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="videoAddress" label="视频地址" width="100" align="center">
                    <template slot-scope="scope">
                        <span @click="showContenHandle(scope.row)" v-if="scope.row.videoAddress.length">
                            <el-link type="primary">查看视频</el-link>
                        </span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="sendTime" label="发送时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.sendTime | formatDate }}
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="110px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateLogMessage && sendUserId==0">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeLogMessage">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectStore">
                            <el-button icon="el-icon-circle-check" type="text" class="success"
                                @click="selectStoreHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看消息详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加消息 -->
        <el-dialog :title="`${editForm.messageId ? '编辑' : '添加'}消息`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="发送人类型" prop="sendUserType">
                    <el-select size="small" v-model="editForm.sendUserType" placeholder="请选择发送人类型" clearable>
                        <el-option v-for="item in sendUserTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="发送人名称" prop="nickName">
                    <el-input size="medium" v-model="editForm.nickName" placeholder="请输入发送人名称"></el-input>
                </el-form-item>
                <el-form-item label="发送人头像" prop="headImg">
                    <UploadImageNewOne :imgList.sync="editForm.headImg" :limit="1" accept="image/*">
                    </UploadImageNewOne>
                </el-form-item> -->
                <el-form-item label="消息内容" prop="content" class="is-required">
                    <el-input size="medium" type="textarea" maxlength="255" v-model="editForm.content"
                        prefix-icon="el-icon-user" placeholder="请填写消息内容"></el-input>
                </el-form-item>
                <el-form-item label="消息类型" prop="messageType" v-if="editForm.messageId==0">
                    <el-select size="small" v-model="editForm.messageType" placeholder="请选择消息类型" clearable
                        @change="changeMessageTypeHandle">
                        <el-option v-for="item in messageTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="消息图集" prop="photoList" v-if="editForm.messageType==1">
                    <UploadImageNewOne :limit="9" :imgList.sync="editForm.photoList" accept="image/*">
                    </UploadImageNewOne>
                </el-form-item>
                <el-form-item label="视频封面" prop="videoImp" v-if="editForm.messageType == 2">
                    <UploadImageNewOne :imgList.sync="editForm.videoImp" :limit="1" accept="image/*">
                    </UploadImageNewOne>
                </el-form-item>
                <el-form-item label="视频地址" prop="videoAddress" v-if="editForm.messageType == 2">
                    <UploadFileNewOne :fileList.sync="editForm.videoAddress" :limit="1" :accept="'video/*'">
                    </UploadFileNewOne>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 消息图集 -->
        <el-dialog :title="`消息图集`" :visible.sync="dialogMessageGalleryVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <SeekBeautyLogMessageGalleryList v-if="dialogMessageGalleryVisible" :messageId="selectMessageId"
                :userId="sendUserId" />
        </el-dialog>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import beautyManager from "@/request/beautyManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import UploadFileNewOne from '@/components/common/subgroup/UploadFileNewOne.vue' //上传图片
import SeekBeautyLogMessageGalleryList from "@/components/page/seekBeauty/SeekBeautyLogMessageGalleryList.vue"

export default {
    mixins: [getList],
    components: {
        ShowContent,
        UploadImageNewOne,
        UploadFileNewOne,
        SeekBeautyLogMessageGalleryList,
    },
    props: ["seekBeautyId", "userId"],
    data() {
        return {
            sendUserId: 0,
            requst: {
                module: beautyManager,
                url: 'logMessageList',
                addUrl: 'saveLogMessage',
                updateUrl: 'updateLogMessage',
            },
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },
            query: {
                seekBeautyId: this.seekBeautyId,    //搜索词
                sendUserType: '',
                nickName: '',
            },
            // 发送人类型【1:用户；2:护理师；3:专家】
            sendUserTypeList: [
                {
                    value: 1,
                    label: "用户",
                },
                {
                    value: 2,
                    label: "护理师",
                },
                {
                    value: 3,
                    label: "专家",
                },
            ],
            messageTypeList: [{ value: 1, label: "图集", }, { value: 2, label: "视频", }],
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                seekBeautyId: this.seekBeautyId,
                messageType: "",
                messageId: 0,
                sendUserType: "",
                nickName: "",
                headImg: "",
                content: "",
                videoAddress: "",
                videoImp: "",
                galleryJson: "",
                photoList: [],
            },
            addRules: {
                // messageType: [{ required: true, message: "请选择消息类型", trigger: "blur" }],
                sendUserType: [{ required: true, message: "请选择发送人类型", trigger: "blur" }],
                content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],
                nickName: [
                    { required: true, message: '请输入发送人名称', trigger: 'blur' },
                ],
                headImg: [{ required: true, message: "请上传发送人头像", trigger: "blur" }],
                photoList: [{ required: true, message: "请上传消息图集", trigger: "blur" }],
                videoImp: [
                    { required: true, message: '请上传视频封面', trigger: 'blur' },
                ],
                videoAddress: [
                    { required: true, message: '请上传视频地址', trigger: 'blur' },
                ],
            },
            //
            selectMessageId: '',
            dialogMessageGalleryVisible: false,
        };
    },
    created() {
        this.sendUserId = this.userId;
    },
    methods: {
        rowContent(row) {
            this.rowInfo.title = "内容";
            this.rowInfo.content = row.content;
            this.dialogSeeContent = true;
        },
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },
        changeMessageTypeHandle() {
            this.editForm.photoList = [];
            this.editForm.videoImp = ''
            this.editForm.videoAddress = ''
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        if (this.editForm.messageType == 1) {
                            // for (let index = 0; index < this.editForm.galleryList.length; index++) {
                            //     const obj = this.editForm.galleryList[index];
                            //     if (this.editForm.galleryId) {
                            //         obj.galleryId = this.editForm.galleryId;
                            //         obj.symptomId = this.symptomId;
                            //     } else {
                            //         obj.symptomId = this.symptomId;
                            //     }
                            // }
                            this.editForm.galleryJson = JSON.stringify(this.editForm.photoList)
                        }
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await this.requst.module[this.editForm.messageId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                seekBeautyId: this.seekBeautyId,
                messageId: 0,
                completeOptionName: "",
                isShow: 1,
                orderWeight: 9,
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm = {
                seekBeautyId: this.seekBeautyId,
                messageId: row.messageId,
                sendUserType: row.sendUserType,
                nickName: row.nickName,
                headImg: row.headImg == null ? '' : row.headImg,
                content: row.content,
                videoAddress: row.videoAddress,
                videoImp: row.videoImp,
                galleryJson: "",
                messageType: "",
            }
            if (this.editForm.videoImp.length > 0) {
                this.editForm.messageType = 2;
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await beautyManager.removeLogMessage({ messageId: row.messageId });
                if (code === 200) {
                    // this.$message.success(message);
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    // this.$message({ message: "删除成功", type: "success" });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        showContenHandle(row) {
            if (row.videoAddress.length > 0) {
                window.open(row.videoAddress)
            }
        },
        showGalleryHandle(row) {
            if (this.permission.logMessageGalleryList) {
                this.selectMessageId = row.messageId;
                this.dialogMessageGalleryVisible = true;
            } else {
                this.$message.error("您没有查看消息图集的权限")
            }
        },
        //选择
        selectStoreHandle(row) {
            this.$emit("changeSelectStore", row)
        },
    },
};
</script>

<style scoped>

</style>

