<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s warp">
        <el-form-item>
          <el-input type="text" placeholder="请输入门店名称" v-model="query.storeName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入用户名称" v-model="query.userName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入护理师名称" v-model="query.nurseName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入专家名称" v-model="query.expertName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入症状名称" v-model="query.symptomName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.seekBeautyState" placeholder="请选择状态" clearable>
            <el-option v-for="item in seekBeautyStateList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.expertReplyStatus" placeholder="专家回复状态">
            <el-option v-for="item in expertReplyStatusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.isCase" placeholder="是否为案例" clearable>
            <el-option v-for="item in isCaseList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.isReferral" placeholder="是否转诊" clearable>
            <el-option v-for="item in isReferralList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.receptionState" placeholder="请选择接待状态" clearable>
            <el-option v-for="item in receptionStateList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain v-if="permission.saveSeekBeautyLog" @click="onAdd">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'seekBeautyId'" v-loading="showLoading"
        :height="tableHeight">
        <el-table-column prop="seekBeautyId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="storeName" label="门店名称" align="center"></el-table-column>
        <el-table-column prop="userName" label="姓名称呼" align="center"></el-table-column>
        <el-table-column prop="userTel" label="手机号" align="center" width="100px"></el-table-column>
        <el-table-column prop="receptionName" label="接待人员名称" align="center" width="100px">
          <template slot-scope="scope"> {{ scope.row.receptionName || '--' }}</template>
        </el-table-column>
        <el-table-column prop="firstSymptomName" label="症状名称" align="center">
          <template slot-scope="scope"> {{ scope.row.firstSymptomName + ' - ' + scope.row.secondSymptomName }}</template>
        </el-table-column>
        <el-table-column prop="nurseName" label="护理师名称" align="center"></el-table-column>
        <el-table-column prop="expertName" label="专家名称" align="center"></el-table-column>
        <el-table-column prop="expertTel" label="专家电话" align="center" width="100px"></el-table-column>
        <el-table-column prop="isReferral" label="是否转诊" width="90" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isReferral == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="referralTime" label="转诊时间" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.referralTime | formatDate }}</template>
        </el-table-column>
        <el-table-column label="转诊记录" align="center">
          <template slot-scope="scope">
            <span @click="showReferralHandle(scope.row)" v-if="scope.row.isReferral">
              <el-link type="primary">查看</el-link>
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="seekBeautyState" label="状态" align="center">
          <template slot-scope="scope">{{ scope.row.seekBeautyState | getStateName(seekBeautyStateList) }}</template>
        </el-table-column>
        <el-table-column prop="consultTime" label="咨询时间" align="center" width="90px"></el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.endTime | formatDate }}</template>
        </el-table-column>
        <el-table-column prop="isCase" label="是否为案例" width="90" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isCase == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="receptionState" label="接待状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.receptionState == 0">已结束</span>
            <span v-else-if="scope.row.receptionState == 1">未接待</span>
            <span v-else-if="scope.row.receptionState == 2">接待中</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="seekHelpState" label="是否求助" width="90" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.seekHelpState == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.addTime | formatDate }}</template>
        </el-table-column>
        <el-table-column label="操作" width="140px" align="center" fixed="right"
          v-if="permission.setCaseLog || permission.updateSeekBeautyLog || permission.removeSeekBeautyLog || permission.selectStore || permission.seekBeautyLogInfo">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content='scope.row.isCase === 1 ? "取消案例" : "设置案例"' placement="top"
              v-if="permission.setCaseLog && scope.row.userId > 0">
              <el-popconfirm title="您确定要修改日志是否为案例状态吗？" icon-color="red" @confirm="setCaseHandle(scope.row)">
                <el-button slot="reference" :icon='scope.row.isCase === 1 ? "el-icon-close" : "el-icon-check"'
                  type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top"
              v-if="permission.updateSeekBeautyLog && scope.row.userId == 0">
              <el-button class="success" type="text" icon="el-icon-edit-outline"
                @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeSeekBeautyLog">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectStore">
              <el-button icon="el-icon-circle-check" type="text" class="success"
                @click="selectStoreHandle(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="求美日志详情" placement="top" v-if="permission.seekBeautyLogInfo">
              <el-button class="success" type="text" icon="el-icon-tickets"
                @click="clickDetailInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="设置2.0V案例" placement="top"
              v-if="scope.row.isCase === 1 && permission.getQuestionnaireAnswer && permission.submitQuestionnaire">
              <el-button class="success" type="text" icon="el-icon-circle-plus-outline"
                @click="clickSetCaseContent(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加 -->
    <el-dialog :title="`${editForm.seekBeautyId ? '编辑' : '添加'}`" :visible.sync="addOrEditDialog" :modal="false"
      :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="咨询时间" prop="consultTime" class="is-required">
          <el-date-picker v-model="editForm.consultTime" type="date" placeholder="请选择咨询时间" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions0">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="姓名称呼" prop="userName">
          <el-input size="medium" v-model="editForm.userName" placeholder="请输入姓名称呼"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="userTel">
          <el-input size="medium" v-model="editForm.userTel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="editForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日" prop="birthday" class="is-required">
          <el-date-picker v-model="editForm.birthday" type="date" placeholder="请选择生日" value-format="yyyy-MM-dd"
            :picker-options="pickerOptions0">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="症状信息" prop="secondSymptomId">
          <el-cascader ref="selectCity" :options="symptomList"
            :value="[this.editForm.firstSymptomId, this.editForm.secondSymptomId]"
            :props="{ value: 'symptomId', label: 'symptomName', children: 'children' }" @change="selectRegionHandle"
            placeholder="请选择症状信息"></el-cascader>
        </el-form-item>
        <el-form-item label="症状扩展信息" v-if="editForm.symptomCompleteList.length > 0" class="is-required">
          <div v-for="(item, index) in editForm.symptomCompleteList" :key="index"
            style="display: flex; -webkit-flex-wrap: wrap;-moz-flex-wrap: wrap;-ms-flex-wrap: wrap;-o-flex-wrap: wrap;flex-wrap: wrap;">
            <!-- <el-form :model="item" :ref="'item'+index" class="nameBox"> -->
            <el-form-item :prop="'symptomCompleteList.' + index + '.selectCompleteOptionId'" :label="item.completeName"
              label-width="90px" :rules="addRules.selectCompleteOptionId">
              <el-select size="small" v-model="item.selectCompleteOptionId" placeholder="请选择症状扩展信息选项"
                style="width: 200px">
                <el-option v-for="grade in item.completeOptionInfoList" :key="grade.completeOptionId"
                  :label="grade.completeOptionName" :value="grade.completeOptionId">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="实拍图集" prop="galleryList" v-if="editForm.seekBeautyId == 0">
          <UploadImageNewOne :limit="9" :imgList.sync="editForm.galleryList" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item label="备注" prop="remark" class="is-required">
          <el-input size="medium" type="textarea" maxlength="255" v-model="editForm.remark" prefix-icon="el-icon-user"
            placeholder="请填写备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 实拍图集 -->
    <el-dialog :title="`实拍图集`" :visible.sync="dialogSeekBeautyLogGalleryVisible" :modal="false" :destroy-on-close="true"
      fullscreen class="absolute">
      <SeekBeautyLogGalleryList v-if="dialogSeekBeautyLogGalleryVisible" :seekBeautyId="selectSeekBeautyId" />
    </el-dialog>
    <!-- 求美日志消息 -->
    <el-dialog :title="`求美日志消息`" :visible.sync="dialogSeekBeautyLogMessageVisible" :modal="false"
      :destroy-on-close="true" fullscreen class="absolute">
      <SeekBeautyLogMessageList v-if="dialogSeekBeautyLogMessageVisible" :seekBeautyId="selectSeekBeautyId"
        :userId="selectUserId" />
    </el-dialog>
    <!-- 转诊记录 -->
    <el-dialog :title="`转诊记录`" :visible.sync="dialogReferralRecordVisible" :modal="false" :destroy-on-close="true"
      fullscreen class="absolute">
      <ReferralRecordList v-if="dialogReferralRecordVisible" :seekBeautyId="selectSeekBeautyId" />
    </el-dialog>
    <!-- 求美日志详情 -->
    <el-dialog :title="`求美日志详情`" :visible.sync="dialogSeekBeautyLogDetailVisible" :modal="false"
      :destroy-on-close="true" fullscreen class="absolute">
      <SeekBeautyLogDetail v-if="dialogSeekBeautyLogDetailVisible" :seekBeautyId="selectSeekBeautyId" />
    </el-dialog>
    <!-- 设置内容 -->
    <el-dialog :title="`设置2.0V案例`" :visible.sync="dialogSetCaseContentVisible" :modal="false" :destroy-on-close="true"
      fullscreen class="absolute">
      <SetCaseContent v-if="dialogSetCaseContentVisible" :seekBeautyId="selectSeekBeautyId"
        :consultationTypeId="selectConsultationTypeId" @hideShow="hideShowMethod"/>
    </el-dialog>

  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import beautyManager from "@/request/beautyManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import SeekBeautyLogGalleryList from "@/components/page/seekBeauty/SeekBeautyLogGalleryList.vue"
import SeekBeautyLogMessageList from "@/components/page/seekBeauty/SeekBeautyLogMessageList.vue"
import ReferralRecordList from "@/components/page/seekBeauty/ReferralRecordList.vue"
import SeekBeautyLogDetail from "@/components/page/seekBeauty/SeekBeautyLogDetail.vue"
import SetCaseContent from "@/components/page/seekBeauty/setCase/SetCaseContent.vue"

export default {
  mixins: [getList],
  components: {
    ShowContent,
    UploadImageNewOne,
    SeekBeautyLogGalleryList,
    SeekBeautyLogMessageList,
    ReferralRecordList,
    SeekBeautyLogDetail,
    SetCaseContent,
  },
  data() {
    return {

      pickerOptions0: {
        disabledDate: (time) => {
          // return time.getTime() >= Date.now() - 86400000;
          // console.log('time=',time.getTime());
          // console.log('now=',Date.now());
          return time.getTime() >= Date.now();
        },
      },
      requst: {
        module: beautyManager,
        url: 'seekBeautyLogList',
        addUrl: 'saveSeekBeautyLog',
        updateUrl: 'updateSeekBeautyLog',
        caseUrl: 'setCaseLog',
      },
      dialogSeeContent: false,
      rowInfo: {
        title: '',
        content: '',
      },
      query: {
        storeName: '',  //门店名称
        userName: '',    //搜索词
        nurseName: '',
        expertName: '',
        symptomName: '',
        seekBeautyState: '',
        expertReplyStatus: -1,
        isCase: '',
        isReferral: '',
      },
      // 状态【1:专家待查看；2:诊断中；3:已结束】
      seekBeautyStateList: [{
        value: 1,
        label: "专家待查看",
      }, {
        value: 2,
        label: "诊断中",
      }, {
        value: 3,
        label: "已结束",
      }],
      // 接待状态【0已结束；1未接待；2】
      receptionStateList: [{
        value: 1,
        label: "未接待",
      }, {
        value: 2,
        label: "接待中",
      }, {
        value: 0,
        label: "已结束",
      }],
      expertReplyStatusList: [{ value: -1, label: "全部", }, { value: 0, label: "未回复", }, { value: 1, label: "已回复", }],
      isCaseList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
      isReferralList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        seekBeautyId: 0,
        consultTime: '',
        userName: '',
        userTel: '',
        sex: 2,
        birthday: '',
        firstSymptomId: '',
        secondSymptomId: '',
        symptomJson: '',
        remark: '',
        galleryJson: '',
        galleryList: [],
        endTime: '',
        referralTime: '',
        firstSymptomName: '',
        secondSymptomName: '',
        symptomCompleteList: [],
      },
      addRules: {
        consultTime: [{ required: true, message: "请选择咨询时间", trigger: "blur" }],
        userTel: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        userName: [{ required: true, message: "请输入姓名称呼", trigger: "blur" }],
        birthday: [{ required: true, message: "请选择生日", trigger: "blur" }],
        secondSymptomId: [{ required: true, message: "请选择症状信息", trigger: "blur" }],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        symptomJson: [{ required: true, message: "请选择症状扩展信息", trigger: "blur" }],
        galleryList: [{ required: true, message: "请上传实拍图集", trigger: "blur" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
        referralTime: [{ required: true, message: "请选择转诊时间", trigger: "blur" }],
        selectCompleteOptionId: [{ required: true, message: '请选择症状扩展信息选项', trigger: 'change' }],
      },
      symptomList: [],
      selectSeekBeautyId: 0,
      dialogSeekBeautyLogGalleryVisible: false,
      selectUserId: 0,
      dialogSeekBeautyLogMessageVisible: false,
      dialogReferralRecordVisible: false,
      dialogSeekBeautyLogDetailVisible: false,
      // 
      selectConsultationTypeId: 0,
      dialogSetCaseContentVisible: false,
    };
  },
  created() {
    this.handlePermission();
    if (this.permission.selectSymptom) {
      this.getSymptomList();
    }
  },
  methods: {
    //获取症状列表
    async getSymptomList() {
      this.showLoading = true;
      // console.log(this.);
      try {
        let data = await beautyManager.symptomList();
        this.symptomList = [];
        if (data.code == 200) {
          this.symptomList = this.toTree(data.data);
        }
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    //处理数据
    toTree(data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach((item) => {
        item.children = "";
      });
      let map = {};
      data.forEach((item) => {
        map[item.symptomId] = item;
      });
      data.forEach((item) => {
        let parent = map[item.parentId];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      result.forEach((item) => {
        if (item.children == "") {
          item.disabled = true;
        }
      });
      return result;
    },
    //获取症状扩展
    async getSymptomCompleteList(selectSymptomId) {
      this.showLoading = true;
      // console.log(this.);
      try {
        let data = await beautyManager.symptomCompleteList({ symptomId: selectSymptomId });
        this.editForm.symptomCompleteList = [];
        if (data.code == 200) {
          this.editForm.symptomCompleteList = this.toAddSelectId(data.data);
        }
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    //处理症状扩展信息数据
    toAddSelectId(data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach((item) => {
        if (this.editForm.seekBeautyId > 0 && this.editForm.symptomJson.length > 0) {
          var jsonArr = JSON.parse(this.editForm.symptomJson);
          jsonArr.forEach(element => {
            if (element.completeId == item.completeId) {
              item.selectCompleteOptionId = element.selectCompleteOptionId;
            }
          });

        } else {
          item.selectCompleteOptionId = '';
        }

        result.push(item);
      });
      return result;
    },
    selectRegionHandle(row) {
      this.editForm.firstSymptomId = row[0];
      this.editForm.secondSymptomId = row[1];
      this.editForm.firstSymptomName = this.$refs[
        "selectCity"
      ].getCheckedNodes()[0].pathLabels[0];
      this.editForm.secondSymptomName = this.$refs[
        "selectCity"
      ].getCheckedNodes()[0].pathLabels[1];

      if (this.permission.selectSymptomComplete) {
        this.getSymptomCompleteList(this.editForm.secondSymptomId);
      }
    },

    rowContent(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = "备注";
          this.rowInfo.content = row.remark;
          this.dialogSeeContent = true;
          break;
        case 2:
          this.rowInfo.title = "症状扩展信息";
          var resultArr = [];
          resultArr = JSON.parse(row.symptomJson);
          var str = '';
          resultArr.forEach(element => {
            console.log(element);
            str = str + element.completeName + ": " + element.completeOptionInfoList[0].completeOptionName + '\n'
          });
          this.rowInfo.content = str;
          this.dialogSeeContent = true;
          break;

        default:
          break;
      }

    },
    hoverContent(row) {
      this.rowInfo = row;
      this.dialogSeeContent = true;
    },
    // 设置求美日志为案例
    async setCaseHandle(row) {
      try {
        let data = await this.requst.module[this.requst.caseUrl]({ seekBeautyId: row.seekBeautyId, isCase: row.isCase > 0 ? 0 : 1 })
        if (data.code == 200) {
          this.$message({
            message: data.message,
            type: 'success'
          });
          this.$emit('success')
          this.refreshData()
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 点击添加
    onAdd() {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      console.log(this.editForm);
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.editForm.seekBeautyId == 0) {
              // for (let index = 0; index < this.editForm.galleryList.length; index++) {
              //     const obj = this.editForm.galleryList[index];
              //     if (this.editForm.galleryId) {
              //         obj.galleryId = this.editForm.galleryId;
              //         obj.symptomId = this.symptomId;
              //     } else {
              //         obj.symptomId = this.symptomId;
              //     }
              // }
              this.editForm.galleryJson = JSON.stringify(this.editForm.galleryList)
            }
            if (this.editForm.symptomCompleteList.length > 0) {
              var jsonArr = this.editForm.symptomCompleteList;
              jsonArr.forEach(element => {
                element.completeOptionInfoList = element.completeOptionInfoList.filter((item) => {
                  return item.completeOptionId == element.selectCompleteOptionId;
                });
              });
              this.editForm.symptomJson = JSON.stringify(jsonArr)
            }

            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[this.editForm.seekBeautyId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success'
              });
              this.$emit('success')
              this.refreshData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }

        }
      })
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm = {
        seekBeautyId: 0,
        consultTime: '',
        userName: '',
        userTel: '',
        sex: 2,
        birthday: '',
        firstSymptomId: '',
        secondSymptomId: '',
        symptomJson: '',
        remark: '',
        galleryJson: '',
        galleryList: [],
        endTime: '',
        referralTime: '',
        firstSymptomName: '',
        secondSymptomName: '',
        symptomCompleteList: [],
      }
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        seekBeautyId: row.seekBeautyId,
        consultTime: row.consultTime,
        userName: row.userName,
        userTel: row.userTel,
        sex: row.sex,
        birthday: row.birthday,
        firstSymptomId: row.firstSymptomId,
        secondSymptomId: row.secondSymptomId,
        symptomJson: row.symptomJson,
        remark: row.remark,
        galleryJson: '',
        galleryList: [],
        endTime: row.endTime,
        referralTime: row.referralTime,
        firstSymptomName: row.firstSymptomName,
        secondSymptomName: row.secondSymptomName,
        symptomCompleteList: [],
      }
      if (this.permission.selectSymptomComplete) {
        this.getSymptomCompleteList(this.editForm.secondSymptomId);
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await beautyManager.removeSeekBeautyLog({ seekBeautyId: row.seekBeautyId });
        if (code === 200) {
          // this.$message.success(message);
          this.$message({
            message: message,
            type: 'success'
          });
          // this.$message({ message: "删除成功", type: "success" });
          this.delRefresh(index);
        }
      } catch (error) {
        console.log(error)
      }
    },

    //选择
    selectStoreHandle(row) {
      this.$emit("changeSelectStore", row)
    },
    //实拍图集
    galleryListHandle(row) {
      if (this.permission.logPhotoList) {
        this.selectSeekBeautyId = row.seekBeautyId;
        this.dialogSeekBeautyLogGalleryVisible = true;
      } else {
        this.$message.error("您没有查看实拍图集的权限")
      }
    },
    //求美日志消息
    messageListHandle(row) {
      if (this.permission.logMessageList) {
        this.selectSeekBeautyId = row.seekBeautyId;
        this.selectUserId = row.userId;
        this.dialogSeekBeautyLogMessageVisible = true;
      } else {
        this.$message.error("您没有查看求美日志消息的权限")
      }
    },
    //转诊记录
    showReferralHandle(row) {
      if (this.permission.lookReferralRecord) {
        this.selectSeekBeautyId = row.seekBeautyId;
        this.dialogReferralRecordVisible = true;
      } else {
        this.$message.error("您没有查看转诊记录的权限")
      }
    },
    // 求美日志详情
    clickDetailInfo(row) {
      if (this.permission.seekBeautyLogInfo) {
        this.selectSeekBeautyId = row.seekBeautyId;
        this.dialogSeekBeautyLogDetailVisible = true;
      } else {
        this.$message.error("您没有查看求美日志详情的权限")
      }
    },
    // 设置2.0V案例
    clickSetCaseContent(row) {
      if (this.permission.getQuestionnaireAnswer && this.permission.submitQuestionnaire && this.permission.getConsultationType && this.permission.getQuestionnaire) {
        this.selectSeekBeautyId = row.seekBeautyId;
        this.selectConsultationTypeId = row.consultationTypeId
        this.dialogSetCaseContentVisible = true;
      } else {
        this.$message.error("您没有权限")
      }
    },
    hideShowMethod(type){
      this.dialogSetCaseContentVisible = false;
      if (type==2) {
        this.refresCurrentPagehData()
      }
    }
  },
};
</script>

<style scoped></style>
