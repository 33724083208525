<template>
  <div class="container">
    <div class="page-container">
      <el-table ref="table" :data="tableData" border stripe :row-key="'recordId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="recordId" label="编号" align="center" width="60" fixed="left">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="userNickName" label="用户昵称" min-width="120" align="center" fixed="left">
        </el-table-column>
        <el-table-column label="用户头像" width="90" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" :src="scope.row.userHeadImg" :preview-src-list="[scope.row.userHeadImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="beforeExpertNickName" label="转诊前专家名称" min-width="120" align="center">
        </el-table-column>
        <el-table-column label="转诊前专家头像" width="110" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" :src="scope.row.beforeExpertEHeadImg" :preview-src-list="[scope.row.beforeExpertEHeadImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="referralExpertNickName" label="被转诊专家名称" min-width="120" align="center">
        </el-table-column>
        <el-table-column label="被转诊专家头像" width="110" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" :src="scope.row.referralExpertHeadImg" :preview-src-list="[scope.row.referralExpertHeadImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="referralTime" label="转诊时间" width="140" align="center">
          <template slot-scope="scope">
            {{ scope.row.referralTime | formatDate }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import beautyManager from '@/request/beautyManager.js'
export default {
  mixins: [getList],
  props: ['seekBeautyId'],
  data() {
    return {
      requst: {
        module: beautyManager,
        url: 'referralRecordInfoList',
        addUrl: '',
        updateUrl: '',
      },
      query: {
        seekBeautyId: this.seekBeautyId,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>