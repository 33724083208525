<template>
  <div class="container">
    <div class="page-container">
      <!-- 客户信息 -->
      <el-card class="box-card">
        <h3>客户信息</h3>
        <el-descriptions :column="2">
          <el-descriptions-item label="门店名称">{{dataModel.provinceName?`${dataModel.provinceName}${dataModel.cityName}|`:''}}{{dataModel.storeName}}</el-descriptions-item>
          <el-descriptions-item label="添加时间">{{ dataModel.addTime | formatDate }}</el-descriptions-item>
          <el-descriptions-item label="姓名称呼">{{dataModel.userName}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{dataModel.userTel}}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <template>
              <span v-if="dataModel.sex == 1">男</span>
              <span v-else-if="dataModel.sex == 2">女</span>
              <span v-else>--</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="生日">{{dataModel.birthday}}</el-descriptions-item>
        </el-descriptions>
      </el-card>
      <!-- 症状信息 -->
      <el-card style="margin-top:15px;" class="box-card">
        <h3>症状信息</h3>
        <el-descriptions :column="2">
          <el-descriptions-item label="一级症状名称">{{dataModel.firstSymptomName}}</el-descriptions-item>
          <el-descriptions-item label="二级症状名称">{{ dataModel.secondSymptomName }}</el-descriptions-item>
        </el-descriptions>
        <h5>扩展信息</h5>
        <el-descriptions :column="2">
          <el-descriptions-item v-for="(item, index) in symptomArr" :key="index" :label="item.completeName">{{item.completeOptionInfoList[0].completeOptionName}}</el-descriptions-item>
        </el-descriptions>
        <h5 style="width:90px;display:inline;">实拍图集</h5>
        <el-button class="success" style="margin-left: 10px;" type="text" icon="el-icon-edit-outline" @click="editSeekBeautyLogGallery"></el-button>
        <el-tooltip style="margin-left: 20px;" effect="dark" content="查看3D皮检报告" placement="top" v-if="permission.getSkinDetection&&reportUrl">
          <el-button type="primary" plain icon="el-icon-document" :disabled="seeReportBtnLoading" :loading="seeReportBtnLoading" @click="clickSeeReport"></el-button>
        </el-tooltip>
        <el-descriptions :column="1" :colon="false">
          <el-descriptions-item>
            <template>
              <UploadImageNewOne :limit="9" :imgList.sync="dataModel.photoList" :imgType="2" accept="image/*"></UploadImageNewOne>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <h5>备注</h5>
        <el-descriptions :column="1" :colon="false">
          <el-descriptions-item>{{dataModel.remark?dataModel.remark:'无'}}</el-descriptions-item>
        </el-descriptions>

      </el-card>
      <!-- 三方消息 -->
      <el-card style="margin-top:15px;margin-bottom: 15px;" class="box-card">
        <div style="display:flex;justify-content: space-between;align-items: center;">
          <h3 style="display:inline;">三方消息
            <el-tooltip style="display:inline;margin-left:20px;" class="item" effect="dark" content="回复求美日志消息" placement="top" v-if="permission.LogMessage&&dataModel.receptionState!=0&&!(dataModel.isCase==1&&dataModel.userId==0&&dataModel.nurseId==0)">
              <el-button type="primary" icon="el-icon-chat-dot-square" @click="replyLogMessage()"></el-button>
            </el-tooltip>
            <!--  style="display: block;" -->
            <el-button class="success" type="text" icon="el-icon-circle-plus-outline" @click="onAdd" v-if="permission.saveLogMessage && sendUserId==0"></el-button>
          </h3>
          <div v-if="!(dataModel.isCase==1&&dataModel.userId==0&&dataModel.nurseId==0)">
            <!-- receptionState 接待状态【0已结束；1未接待；2接待中】 -->
            <el-tooltip class="item" effect="dark" content="开始接待" placement="top" v-if="permission.reception&&dataModel.receptionState==1">
              <el-button type="primary" icon="el-icon-phone-outline" @click="customerReception(1)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="结束接待" placement="top" v-if="permission.reception&&dataModel.receptionState==2">
              <el-button type="danger" icon="el-icon-phone" @click="customerReception(2)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="放弃接待" placement="top" v-if="permission.reception&&dataModel.receptionState==2">
              <el-button type="info" icon="el-icon-circle-close" @click="customerReception(3)"></el-button>
            </el-tooltip>
            <!-- seekHelpState 求助专家状态标识【0不求助 1求助】 -->
            <el-tooltip class="item" effect="dark" content="求助专家" placement="top" v-if="permission.seekHelp&&dataModel.seekHelpState==0&&dataModel.receptionState!=0">
              <el-button type="success" icon="el-icon-s-custom" @click="seekHelp()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="不需要回复" placement="top" v-if="permission.noReply&&dataModel.isReply==0">
              <el-button type="warning" icon="el-icon-chat-line-round" @click="noReply()"></el-button>
            </el-tooltip>
          </div>
        </div>
        <div v-for="(item, index) in dataModel.messageInfoList" :key="index" style="margin-top:15px;display: flex;">
          <el-image class="table-td-thumb" :src="item.headImg" :preview-src-list="[item.headImg]" fit="cover" style="width:50px;height:50px;border-radius:25px;" v-if="item.headImg!=null&&item.headImg!=''">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div style="display:inline;width:90%;margin-top:2px">
            <el-tag style="margin-left:10px" size="small">{{item.sendUserType | getStateName(sendUserTypeList)}}</el-tag>
            <div style="display:inline">{{"&nbsp;"+item.nickName+"&nbsp;&nbsp;&nbsp;"}}</div>
            <div style="display:inline">{{item.sendTime | formatDate}}</div>
            <div style="display:inline">{{"&nbsp;&nbsp;&nbsp;"}}</div>
            <!-- 操作 -->
            <el-tooltip class="item" effect="dark" content="编辑消息" placement="top" v-if="permission.updateLogMessage && sendUserId==0">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(item)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑消息图集" placement="top" v-if="permission.logMessageGalleryList && (item.videoImp==null||item.videoImp=='') && sendUserId==0">
              <el-button class="success" type="text" icon="el-icon-picture-outline" @click="showGalleryHandle(item)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeLogMessage">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(item)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger"></el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑消息" placement="top" v-if="item.sendUserType==3&&!isSysSendCont(item.content)">
              <el-button class="success" type="text" icon="el-icon-edit" @click="clickEditMessage(item)"></el-button>
            </el-tooltip>
            <div style="margin-left:10px;margin-top:5px;white-space: pre-wrap;">{{item.content}}</div>
            <UploadImageNewOne style="margin-left:10px;margin-top:5px" :limit="9" :imgList.sync="item.galleryList" :imgType="2" accept="image/*"></UploadImageNewOne>
            <el-image class="table-td-thumb" :src="item.videoImp" fit="cover" style="margin-left:10px;margin-top:5px;width:80px;height:80px;border-radius:5px;" v-if="item.videoImp!=null&& item.videoImp!=''">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button circle style="margin-left:-63px;margin-top:25px;position: absolute;background-color:transparent;" v-if="item.videoImp!=null&& item.videoImp!='' && item.videoAddress!=null&& item.videoAddress!=''" @click="showVideoHandle(item)">
              <i style="font-size: 25px;" class="el-icon-video-play"></i>
            </el-button>
          </div>
        </div>
      </el-card>

    </div>
    <!-- 查看消息详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加消息 -->
    <el-dialog :title="`${editForm.messageId ? '编辑' : '添加'}消息`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="发送人类型" prop="sendUserType">
          <el-select size="small" v-model="editForm.sendUserType" placeholder="请选择发送人类型" clearable>
            <el-option v-for="item in sendUserTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="发送人名称" prop="nickName">
          <el-input size="medium" v-model="editForm.nickName" placeholder="请输入发送人名称"></el-input>
        </el-form-item>
        <el-form-item label="发送人头像" prop="headImg">
          <UploadImageNewOne :imgList.sync="editForm.headImg" :limit="1" accept="image/*">
          </UploadImageNewOne>
        </el-form-item> -->
        <el-form-item label="消息内容" prop="content" class="is-required">
          <el-input size="medium" type="textarea" maxlength="255" v-model="editForm.content" prefix-icon="el-icon-user" placeholder="请填写消息内容"></el-input>
        </el-form-item>
        <el-form-item label="消息类型" prop="messageType" v-if="editForm.messageId==0">
          <el-select size="small" v-model="editForm.messageType" placeholder="请选择消息类型" clearable @change="changeMessageTypeHandle">
            <el-option v-for="item in messageTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消息图集" prop="photoList" v-if="editForm.messageType==1">
          <UploadImageNewOne :limit="9" :imgList.sync="editForm.photoList" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item label="视频封面" prop="videoImp" v-if="editForm.messageType == 2">
          <UploadImageNewOne :imgList.sync="editForm.videoImp" :limit="1" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item label="视频地址" prop="videoAddress" v-if="editForm.messageType == 2">
          <UploadFileNewOne :fileList.sync="editForm.videoAddress" :limit="1" :accept="'video/*'"></UploadFileNewOne>
        </el-form-item>
        <el-form-item label="发送时间" prop="sendTime" class="is-required">
          <!-- :picker-options="pickerOptions0" -->
          <el-date-picker v-model="editForm.sendTime" type="datetime" placeholder="选择发送时间" value-format="timestamp"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 三期 回复求美消息 -->
    <el-dialog :title="`${mast==1 ? '编辑' : '回复'}消息`" :visible.sync="replyOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="140px" ref="replySeekBeauty" :model="replySeekBeauty" :rules="replyRules">
        <el-form-item label="常用方案" prop="">
          <el-button type="primary" plain @click="clickSelectSchemeBtn">选择常用方案</el-button>
        </el-form-item>
        <el-form-item label="消息内容" prop="content" class="is-required">
          <el-input type="textarea" maxlength="255" :autosize="{ minRows: 2, maxRows: 10}" v-model="replySeekBeauty.content" prefix-icon="el-icon-user" placeholder="请填写消息内容"></el-input>
        </el-form-item>
        <el-form-item v-if="mast==0&&photo" label="图片图集" prop="galleryJson">
          <UploadImageNewOne :limit="9" :imgList.sync="replySeekBeauty.galleryJson" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item v-if="mast==0&&video" label="视频封面" prop="videoImp">
          <UploadImageNewOne :imgList.sync="replySeekBeauty.videoImp" :limit="1" accept="image/*"></UploadImageNewOne>
        </el-form-item>
        <el-form-item v-if="mast==0&&video" label="视频地址" prop="videoAddress">
          <UploadFileNewOne :fileList.sync="replySeekBeauty.videoAddress" :limit="1" :accept="'video/*'"></UploadFileNewOne>
        </el-form-item>
        <el-form-item v-if="mast==0" label="是否需要回复" prop="isNeedreply">
          <el-checkbox v-model="replySeekBeauty.isNeedreply" :false-label="0" :true-label="1"></el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="replyBtnLoading" @click="clickreplyConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 实拍图集 -->
    <el-dialog :title="`实拍图集`" :visible.sync="dialogSeekBeautyLogGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <SeekBeautyLogGalleryList v-if="dialogSeekBeautyLogGalleryVisible" :seekBeautyId="selectSeekBeautyId" />
    </el-dialog>
    <!-- 消息图集 -->
    <el-dialog :title="`消息图集`" :visible.sync="dialogMessageGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <SeekBeautyLogMessageGalleryList v-if="dialogMessageGalleryVisible" :messageId="selectMessageId" :userId="sendUserId" @changeMessageGallery="handleChangeMessageGallery" />
    </el-dialog>
    <!-- 常用方案 -->
    <el-dialog :title="`常用方案`" :visible.sync="dialogCommonSchemeVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <commonSchemeList v-if="dialogCommonSchemeVisible" :symptom_id="dataModel.secondSymptomId" @selectSchemeSuccess="selectSchemeSuccess"></commonSchemeList>
    </el-dialog>

  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import beautyManager from "@/request/beautyManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import UploadFileNewOne from '@/components/common/subgroup/UploadFileNewOne.vue' //上传图片
import SeekBeautyLogGalleryList from "@/components/page/seekBeauty/SeekBeautyLogGalleryList.vue"
import SeekBeautyLogMessageGalleryList from "@/components/page/seekBeauty/SeekBeautyLogMessageGalleryList.vue"
import commonSchemeList from "@/components/page/seekBeauty/CommonSchemeList.vue"    //常用方案

export default {
  mixins: [getList],
  components: {
    ShowContent,
    UploadImageNewOne,
    UploadFileNewOne,
    SeekBeautyLogGalleryList,
    SeekBeautyLogMessageGalleryList,
    commonSchemeList,    //常用方案
  },
  props: ["seekBeautyId"],
  data () {
    var validatePass = (rule, value, callback) => {
      if (this.replySeekBeauty.videoAddress != '') {
        if (this.replySeekBeauty.videoImp == '') {
          callback(new Error('请上传视频封面'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (this.replySeekBeauty.videoImp != '') {
        if (this.replySeekBeauty.videoAddress == '') {
          callback(new Error('请上传视频地址'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      // pickerOptions0: {
      //     disabledDate: (time) => {
      //         if (this.editForm.sendTime != "") {
      //             return (
      //                 time.getTime() < Date.now() - 86400000 ||
      //                 time.getTime() > this.editForm.sendTime
      //             );
      //         } else {
      //             return time.getTime() < Date.now() - 86400000;
      //         }
      //     },
      // },
      dataModel: '',
      symptomArr: [],
      sendUserId: 0,
      video: true,//控制视频的显示和隐藏
      photo: true,//控制图集的显示和隐藏
      // requst: {
      //     module: beautyManager,
      //     url: 'logMessageList',
      //     addUrl: 'saveLogMessage',
      //     updateUrl: 'updateLogMessage',
      // },
      requstModel: {
        module: beautyManager,
        url: 'logMessageList',
        addUrl: 'saveLogMessage',
        updateUrl: 'updateLogMessage',
      },
      requstMessage: {
        module: beautyManager,
        addUrl: 'replySeekBeautyLogMessage',
        updateUrl: 'updateReplySeekBeauty',
      },
      dialogSeeContent: false,
      rowInfo: {
        title: '',
        content: '',
      },
      query: {
        seekBeautyId: this.seekBeautyId,    //搜索词
      },
      mast: 0,
      // 发送人类型【1:用户；2:护理师；3:专家】
      sendUserTypeList: [
        {
          value: 1,
          label: "用户",
        },
        {
          value: 2,
          label: "护理师",
        },
        {
          value: 3,
          label: "专家",
        },
      ],
      messageTypeList: [{ value: 1, label: "图集", }, { value: 2, label: "视频", }],
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      replySeekBeauty: {
        seekBeautyId: this.seekBeautyId,
        content: '',
        messageId: '',
        videoAddress: '',
        videoImp: '',
        galleryJson: [],
        isNeedreply: 1
      },
      replyRules: {
        content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],
        // galleryJson: [{ message: "请上传消息图集", trigger: "blur" }],
        videoImp: [
          { required: false, validator: validatePass, trigger: 'change' },
        ],
        videoAddress: [
          { required: false, validator: validatePass2, trigger: 'change' },
        ],
      },
      editForm: {
        seekBeautyId: this.seekBeautyId,
        messageType: "",
        messageId: 0,
        sendUserType: "",
        nickName: "",
        headImg: "",
        content: "",
        videoAddress: "",
        videoImp: "",
        galleryJson: "",
        photoList: [],
        sendTime: "",
      },
      addRules: {
        // messageType: [{ required: true, message: "请选择消息类型", trigger: "blur" }],
        sendUserType: [{ required: true, message: "请选择发送人类型", trigger: "blur" }],
        content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],
        nickName: [
          { required: true, message: '请输入发送人名称', trigger: 'blur' },
        ],
        headImg: [{ required: true, message: "请上传发送人头像", trigger: "blur" }],
        photoList: [{ required: true, message: "请上传消息图集", trigger: "blur" }],
        videoImp: [
          { required: true, message: '请上传视频封面', trigger: 'blur' },
        ],
        videoAddress: [
          { required: true, message: '请上传视频地址', trigger: 'blur' },
        ],
        sendTime: [
          { required: true, message: "请选择发送时间", trigger: "blur" },
        ],
      },
      //
      selectSeekBeautyId: 0,
      dialogSeekBeautyLogGalleryVisible: false,
      selectMessageId: 0,
      dialogMessageGalleryVisible: false,
      replyOrEditDialog: false,
      replyBtnLoading: false,
      dialogCommonSchemeVisible: false,   //常用方案
      seeReportBtnLoading: false,
      reportUrl: '',   //3d报告url
    };
  },
  watch: {
    'replySeekBeauty.galleryJson': {
      handler (newval, oldval) {
        if (newval.length > 0) {
          this.video = false
        } else {
          this.video = true
        }
      }
    },
    'replySeekBeauty.videoAddress': {
      handler (newval, oldval) {
        console.log(newval)
        if (newval != '') {
          this.photo = false
        } else {
          this.photo = true
        }
      }
    },
    'replySeekBeauty.videoImp': {
      handler (newval, oldval) {
        console.log(newval)
        if (newval != '') {
          this.photo = false
        } else {
          this.photo = true
        }
      }
    },
  },
  async created () {
    this.handlePermission();
    if (this.permission.seekBeautyLogInfo) {
      await this.getSeekBeautyLogInfo();
    }
    if (this.permission.getSkinDetection) {
      await this.getReportData();   //获取3d报告结果
    }
  },
  computed: {
    isSysSendCont () { //是否是系统发送默认消息
      return (value) => {
        if (value == '求美信息查看中，稍后给予回复，请耐心等待...' || value == '求美信息查看中，稍后给予回复，请耐心等待...，如有紧急情况，请直接联系微信客服。') {
          return true
        } else {
          return false
        }
      }
    },
  },
  methods: {
    //不需要回复
    async noReply () {
      try {
        let data = await beautyManager.unwantedMessage({ seekBeautyId: this.seekBeautyId });
        if (data.code == 200) {
          this.$message({message: data.message,type: 'success'});
          this.getSeekBeautyLogInfo()
          this.getWaitReplyNumber()    //操作后刷新全部待回复数量
        }
      } catch (error) {
        console.log(error)
      }
    },
    //求助专家
    async seekHelp () {
      try {
        let data = await beautyManager.specialistHelp({ seekBeautyId: this.seekBeautyId });
        if (data.code == 200) {
          this.$message({
            message: data.message,
            type: 'success'
          });
          this.getSeekBeautyLogInfo()
        }
      } catch (error) {
        console.log(error)
      }
    },
    //接待功能1开始2结束3放弃
    async customerReception (item) {
      try {
        let data = await beautyManager.customerReception({ seekBeautyId: this.seekBeautyId, type: item });
        if (data.code == 200) {
          this.$message({ message: data.message,type: 'success' });
          this.getSeekBeautyLogInfo()
        }
      } catch (error) {
        console.log(error)
      }
    },
    //回复求美日志信息
    replyLogMessage () {
      this.mast = 0
      this.replyOrEditDialog = true
    },
    async getSeekBeautyLogInfo (row, index) {
      try {
        let data = await beautyManager.seekBeautyLogList(this.query);
        if (data.code === 200) {
          // this.$message({
          //     message: data.message,
          //     type: 'success'
          // });

          this.dataModel = data.data[0];
          this.symptomArr = [];
          this.symptomArr = JSON.parse(this.dataModel.symptomJson);
          this.sendUserId = this.dataModel.userId;
        }
      } catch (error) {
        console.log(error)
      }
    },
    rowContent (row) {
      this.rowInfo.title = "内容";
      this.rowInfo.content = row.content;
      this.dialogSeeContent = true;
    },
    hoverContent (row) {
      this.rowInfo = row;
      this.dialogSeeContent = true;
    },
    changeMessageTypeHandle () {
      this.editForm.photoList = [];
      this.editForm.videoImp = ''
      this.editForm.videoAddress = ''
    },
    //修改回复信息
    clickEditMessage (item) {
      this.mast = 1
      this.replySeekBeauty = {
        seekBeautyId: item.seekBeautyId,
        content: item.content,
        messageId: item.messageId,
        videoAddress: item.videoAddress,
        videoImp: item.videoImp,
        galleryJson: item.galleryJson ? item.galleryJson : [],
        isNeedreply: item.isNeedreply
      }
      this.replyOrEditDialog = true
    },
    // 点击添加
    onAdd () {
      this.addOrEditDialog = true
    },
    //回复求美日志确定
    async clickreplyConfirm () {
      this.$refs.replySeekBeauty.validate(async (valid) => {
        if (valid) {
          try {
            this.replyBtnLoading = true
            this.replySeekBeauty.galleryJson = this.replySeekBeauty.galleryJson.length > 0 ? JSON.stringify(this.replySeekBeauty.galleryJson) : ''
            let dataValue = JSON.parse(JSON.stringify(this.replySeekBeauty))
            let data = await this.requstMessage.module[this.mast == 1 ? this.requstMessage.updateUrl : this.requstMessage.addUrl](dataValue)
            if (data.code == 200) {
              this.$message({ message: data.message,type: 'success'});
              this.resetAddOrEditData()
              this.getSeekBeautyLogInfo()
              this.getWaitReplyNumber()    //操作后刷新全部待回复数量
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.replyBtnLoading = false
          }
        }
      })
    },
    // 点击添加---取消
    clickAddOrEditCancel () {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm () {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.editForm.messageType == 1) {
              this.editForm.galleryJson = JSON.stringify(this.editForm.photoList)
            }
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requstModel.module[this.editForm.messageId > 0 ? this.requstModel.updateUrl : this.requstModel.addUrl](dataValue)
            if (data.code == 200) {
              this.$message({message: data.message,type: 'success'});
              this.resetAddOrEditData()
              this.getSeekBeautyLogInfo()
              this.getWaitReplyNumber()    //操作后刷新全部待回复数量
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }

        }
      })
    },

    // 清除添加数据
    resetAddOrEditData () {
      this.addOrEditDialog = false
      this.replyOrEditDialog = false
      this.editForm = {
        seekBeautyId: this.seekBeautyId,
        messageType: "",
        messageId: 0,
        sendUserType: "",
        nickName: "",
        headImg: "",
        content: "",
        videoAddress: "",
        videoImp: "",
        galleryJson: "",
        photoList: [],
        sendTime: "",
      }
      this.replySeekBeauty = {
        seekBeautyId: this.seekBeautyId,
        content: '',
        videoAddress: '',
        videoImp: '',
        galleryJson: [],
        isNeedreply: 1
      }
    },
    // 点击编辑信息
    async clickEditInfo (row) {
      this.editForm = {
        seekBeautyId: this.seekBeautyId,
        messageId: row.messageId,
        sendUserType: row.sendUserType,
        nickName: row.nickName,
        headImg: row.headImg == null ? '' : row.headImg,
        content: row.content,
        videoAddress: row.videoAddress,
        videoImp: row.videoImp,
        galleryJson: "",
        messageType: "",
        sendTime: row.sendTime,
      }
      if (this.editForm.videoImp.length > 0) {
        this.editForm.messageType = 2;
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin (row) {
      try {
        let { code, message } = await beautyManager.removeLogMessage({ messageId: row.messageId });
        if (code === 200) {
          // this.$message.success(message);
          this.$message({
            message: message,
            type: 'success'
          });
          this.getSeekBeautyLogInfo()
        }
      } catch (error) {
        console.log(error)
      }
    },
    showGalleryHandle (row) {
      if (this.permission.logMessageGalleryList) {
        this.selectMessageId = row.messageId;
        this.dialogMessageGalleryVisible = true;
      } else {
        this.$message.error("您没有查看消息图集的权限")
      }
    },
    //编辑实拍图集
    editSeekBeautyLogGallery () {
      if (this.permission.logPhotoList) {
        this.selectSeekBeautyId = this.dataModel.seekBeautyId;
        this.dialogSeekBeautyLogGalleryVisible = true;
      } else {
        this.$message.error("您没有查看实拍图集的权限")
      }
    },
    //视频
    showVideoHandle (row) {
      window.open(row.videoAddress)
    },
    //消息图集改变
    handleChangeMessageGallery () {
      this.getSeekBeautyLogInfo()
    },
    // 点击选择常用方案
    clickSelectSchemeBtn () {
      if (!this.permission.selectCommonSchemeInfo) return this.$message.error("您没有选择常用方案的权限");
      this.dialogCommonSchemeVisible = true
    },
    // 选择方案成功
    selectSchemeSuccess (scheme_val) {
      this.replySeekBeauty.content = scheme_val.schemeContent
      this.dialogCommonSchemeVisible = false
      this.$refs['replySeekBeauty'].clearValidate('content');
    },
    // 获取3d报告结果
    async getReportData () {
      console.log('000')
      if(!(this.dataModel.appId&&this.dataModel.appSecret&&this.dataModel.channelId&&this.dataModel.storeNum)) return;
      let { code, data, message } = await beautyManager.getSkinDetection({
        storeId: this.dataModel.storeId,   //门店id
        userTel: this.dataModel.userTel,   //用户手机号 '18658868993'    //
      });
      if (code == 200) {
        if (data.length) {
          if (data[0].share_url) {
            this.reportUrl = data[0].share_url
          }
        }
      }
    },
    // 点击查看3D皮检报告
    async clickSeeReport () {
      window.open(this.reportUrl, '_blank')
    },
    // 获取待回复数量
    getWaitReplyNumber () {
      this.$bus.$emit('refreshHomeNumData', {
        isRefreshLogin: true,
      });
    },

  },
};
</script>

<style scoped>
</style>

