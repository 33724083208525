var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("h3", [_vm._v("客户信息")]),
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "门店名称" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.dataModel.provinceName
                          ? `${_vm.dataModel.provinceName}${_vm.dataModel.cityName}|`
                          : ""
                      ) + _vm._s(_vm.dataModel.storeName)
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "添加时间" } }, [
                    _vm._v(_vm._s(_vm._f("formatDate")(_vm.dataModel.addTime))),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "姓名称呼" } }, [
                    _vm._v(_vm._s(_vm.dataModel.userName)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                    _vm._v(_vm._s(_vm.dataModel.userTel)),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "性别" } },
                    [
                      [
                        _vm.dataModel.sex == 1
                          ? _c("span", [_vm._v("男")])
                          : _vm.dataModel.sex == 2
                          ? _c("span", [_vm._v("女")])
                          : _c("span", [_vm._v("--")]),
                      ],
                    ],
                    2
                  ),
                  _c("el-descriptions-item", { attrs: { label: "生日" } }, [
                    _vm._v(_vm._s(_vm.dataModel.birthday)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card", staticStyle: { "margin-top": "15px" } },
            [
              _c("h3", [_vm._v("症状信息")]),
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "一级症状名称" } },
                    [_vm._v(_vm._s(_vm.dataModel.firstSymptomName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "二级症状名称" } },
                    [_vm._v(_vm._s(_vm.dataModel.secondSymptomName))]
                  ),
                ],
                1
              ),
              _c("h5", [_vm._v("扩展信息")]),
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                _vm._l(_vm.symptomArr, function (item, index) {
                  return _c(
                    "el-descriptions-item",
                    { key: index, attrs: { label: item.completeName } },
                    [
                      _vm._v(
                        _vm._s(
                          item.completeOptionInfoList[0].completeOptionName
                        )
                      ),
                    ]
                  )
                }),
                1
              ),
              _c("h5", { staticStyle: { width: "90px", display: "inline" } }, [
                _vm._v("实拍图集"),
              ]),
              _c("el-button", {
                staticClass: "success",
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "text", icon: "el-icon-edit-outline" },
                on: { click: _vm.editSeekBeautyLogGallery },
              }),
              _vm.permission.getSkinDetection && _vm.reportUrl
                ? _c(
                    "el-tooltip",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        effect: "dark",
                        content: "查看3D皮检报告",
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          plain: "",
                          icon: "el-icon-document",
                          disabled: _vm.seeReportBtnLoading,
                          loading: _vm.seeReportBtnLoading,
                        },
                        on: { click: _vm.clickSeeReport },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-descriptions",
                { attrs: { column: 1, colon: false } },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      [
                        _c("UploadImageNewOne", {
                          attrs: {
                            limit: 9,
                            imgList: _vm.dataModel.photoList,
                            imgType: 2,
                            accept: "image/*",
                          },
                          on: {
                            "update:imgList": function ($event) {
                              return _vm.$set(
                                _vm.dataModel,
                                "photoList",
                                $event
                              )
                            },
                            "update:img-list": function ($event) {
                              return _vm.$set(
                                _vm.dataModel,
                                "photoList",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("h5", [_vm._v("备注")]),
              _c(
                "el-descriptions",
                { attrs: { column: 1, colon: false } },
                [
                  _c("el-descriptions-item", [
                    _vm._v(
                      _vm._s(_vm.dataModel.remark ? _vm.dataModel.remark : "无")
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "15px", "margin-bottom": "15px" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "h3",
                    { staticStyle: { display: "inline" } },
                    [
                      _vm._v("三方消息 "),
                      _vm.permission.LogMessage &&
                      _vm.dataModel.receptionState != 0 &&
                      !(
                        _vm.dataModel.isCase == 1 &&
                        _vm.dataModel.userId == 0 &&
                        _vm.dataModel.nurseId == 0
                      )
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              staticStyle: {
                                display: "inline",
                                "margin-left": "20px",
                              },
                              attrs: {
                                effect: "dark",
                                content: "回复求美日志消息",
                                placement: "top",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-chat-dot-square",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.replyLogMessage()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.permission.saveLogMessage && _vm.sendUserId == 0
                        ? _c("el-button", {
                            staticClass: "success",
                            attrs: {
                              type: "text",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.onAdd },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  !(
                    _vm.dataModel.isCase == 1 &&
                    _vm.dataModel.userId == 0 &&
                    _vm.dataModel.nurseId == 0
                  )
                    ? _c(
                        "div",
                        [
                          _vm.permission.reception &&
                          _vm.dataModel.receptionState == 1
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "开始接待",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-phone-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.customerReception(1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.reception &&
                          _vm.dataModel.receptionState == 2
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "结束接待",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-phone",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.customerReception(2)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.reception &&
                          _vm.dataModel.receptionState == 2
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "放弃接待",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "info",
                                      icon: "el-icon-circle-close",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.customerReception(3)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.seekHelp &&
                          _vm.dataModel.seekHelpState == 0 &&
                          _vm.dataModel.receptionState != 0
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "求助专家",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "success",
                                      icon: "el-icon-s-custom",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.seekHelp()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.permission.noReply && _vm.dataModel.isReply == 0
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "不需要回复",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "warning",
                                      icon: "el-icon-chat-line-round",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.noReply()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._l(_vm.dataModel.messageInfoList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: { "margin-top": "15px", display: "flex" },
                  },
                  [
                    item.headImg != null && item.headImg != ""
                      ? _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            staticStyle: {
                              width: "50px",
                              height: "50px",
                              "border-radius": "25px",
                            },
                            attrs: {
                              src: item.headImg,
                              "preview-src-list": [item.headImg],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "inline",
                          width: "90%",
                          "margin-top": "2px",
                        },
                      },
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStateName")(
                                  item.sendUserType,
                                  _vm.sendUserTypeList
                                )
                              )
                            ),
                          ]
                        ),
                        _c("div", { staticStyle: { display: "inline" } }, [
                          _vm._v(_vm._s(" " + item.nickName + "   ")),
                        ]),
                        _c("div", { staticStyle: { display: "inline" } }, [
                          _vm._v(_vm._s(_vm._f("formatDate")(item.sendTime))),
                        ]),
                        _c("div", { staticStyle: { display: "inline" } }, [
                          _vm._v(_vm._s("   ")),
                        ]),
                        _vm.permission.updateLogMessage && _vm.sendUserId == 0
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑消息",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.logMessageGalleryList &&
                        (item.videoImp == null || item.videoImp == "") &&
                        _vm.sendUserId == 0
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑消息图集",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-picture-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showGalleryHandle(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeLogMessage
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.sendUserType == 3 &&
                        !_vm.isSysSendCont(item.content)
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑消息",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: { type: "text", icon: "el-icon-edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditMessage(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-top": "5px",
                              "white-space": "pre-wrap",
                            },
                          },
                          [_vm._v(_vm._s(item.content))]
                        ),
                        _c("UploadImageNewOne", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-top": "5px",
                          },
                          attrs: {
                            limit: 9,
                            imgList: item.galleryList,
                            imgType: 2,
                            accept: "image/*",
                          },
                          on: {
                            "update:imgList": function ($event) {
                              return _vm.$set(item, "galleryList", $event)
                            },
                            "update:img-list": function ($event) {
                              return _vm.$set(item, "galleryList", $event)
                            },
                          },
                        }),
                        item.videoImp != null && item.videoImp != ""
                          ? _c(
                              "el-image",
                              {
                                staticClass: "table-td-thumb",
                                staticStyle: {
                                  "margin-left": "10px",
                                  "margin-top": "5px",
                                  width: "80px",
                                  height: "80px",
                                  "border-radius": "5px",
                                },
                                attrs: { src: item.videoImp, fit: "cover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.videoImp != null &&
                        item.videoImp != "" &&
                        item.videoAddress != null &&
                        item.videoAddress != ""
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-left": "-63px",
                                  "margin-top": "25px",
                                  position: "absolute",
                                  "background-color": "transparent",
                                },
                                attrs: { circle: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoHandle(item)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-video-play",
                                  staticStyle: { "font-size": "25px" },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.messageId ? "编辑" : "添加"}消息`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "140px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发送人类型", prop: "sendUserType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择发送人类型",
                        clearable: "",
                      },
                      model: {
                        value: _vm.editForm.sendUserType,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "sendUserType", $$v)
                        },
                        expression: "editForm.sendUserType",
                      },
                    },
                    _vm._l(_vm.sendUserTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "消息内容", prop: "content" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "textarea",
                      maxlength: "255",
                      "prefix-icon": "el-icon-user",
                      placeholder: "请填写消息内容",
                    },
                    model: {
                      value: _vm.editForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "content", $$v)
                      },
                      expression: "editForm.content",
                    },
                  }),
                ],
                1
              ),
              _vm.editForm.messageId == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "消息类型", prop: "messageType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            placeholder: "请选择消息类型",
                            clearable: "",
                          },
                          on: { change: _vm.changeMessageTypeHandle },
                          model: {
                            value: _vm.editForm.messageType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "messageType", $$v)
                            },
                            expression: "editForm.messageType",
                          },
                        },
                        _vm._l(_vm.messageTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.messageType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "消息图集", prop: "photoList" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          limit: 9,
                          imgList: _vm.editForm.photoList,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(_vm.editForm, "photoList", $event)
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(_vm.editForm, "photoList", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.messageType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频封面", prop: "videoImp" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          imgList: _vm.editForm.videoImp,
                          limit: 1,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(_vm.editForm, "videoImp", $event)
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(_vm.editForm, "videoImp", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.messageType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频地址", prop: "videoAddress" } },
                    [
                      _c("UploadFileNewOne", {
                        attrs: {
                          fileList: _vm.editForm.videoAddress,
                          limit: 1,
                          accept: "video/*",
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(
                              _vm.editForm,
                              "videoAddress",
                              $event
                            )
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(
                              _vm.editForm,
                              "videoAddress",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "发送时间", prop: "sendTime" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择发送时间",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.editForm.sendTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "sendTime", $$v)
                      },
                      expression: "editForm.sendTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.mast == 1 ? "编辑" : "回复"}消息`,
            visible: _vm.replyOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.replyOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "replySeekBeauty",
              attrs: {
                "label-width": "140px",
                model: _vm.replySeekBeauty,
                rules: _vm.replyRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "常用方案", prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.clickSelectSchemeBtn },
                    },
                    [_vm._v("选择常用方案")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "消息内容", prop: "content" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "255",
                      autosize: { minRows: 2, maxRows: 10 },
                      "prefix-icon": "el-icon-user",
                      placeholder: "请填写消息内容",
                    },
                    model: {
                      value: _vm.replySeekBeauty.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.replySeekBeauty, "content", $$v)
                      },
                      expression: "replySeekBeauty.content",
                    },
                  }),
                ],
                1
              ),
              _vm.mast == 0 && _vm.photo
                ? _c(
                    "el-form-item",
                    { attrs: { label: "图片图集", prop: "galleryJson" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          limit: 9,
                          imgList: _vm.replySeekBeauty.galleryJson,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "galleryJson",
                              $event
                            )
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "galleryJson",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.mast == 0 && _vm.video
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频封面", prop: "videoImp" } },
                    [
                      _c("UploadImageNewOne", {
                        attrs: {
                          imgList: _vm.replySeekBeauty.videoImp,
                          limit: 1,
                          accept: "image/*",
                        },
                        on: {
                          "update:imgList": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "videoImp",
                              $event
                            )
                          },
                          "update:img-list": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "videoImp",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.mast == 0 && _vm.video
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频地址", prop: "videoAddress" } },
                    [
                      _c("UploadFileNewOne", {
                        attrs: {
                          fileList: _vm.replySeekBeauty.videoAddress,
                          limit: 1,
                          accept: "video/*",
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "videoAddress",
                              $event
                            )
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(
                              _vm.replySeekBeauty,
                              "videoAddress",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.mast == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "是否需要回复", prop: "isNeedreply" } },
                    [
                      _c("el-checkbox", {
                        attrs: { "false-label": 0, "true-label": 1 },
                        model: {
                          value: _vm.replySeekBeauty.isNeedreply,
                          callback: function ($$v) {
                            _vm.$set(_vm.replySeekBeauty, "isNeedreply", $$v)
                          },
                          expression: "replySeekBeauty.isNeedreply",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.replyBtnLoading },
                  on: { click: _vm.clickreplyConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: `实拍图集`,
            visible: _vm.dialogSeekBeautyLogGalleryVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSeekBeautyLogGalleryVisible = $event
            },
          },
        },
        [
          _vm.dialogSeekBeautyLogGalleryVisible
            ? _c("SeekBeautyLogGalleryList", {
                attrs: { seekBeautyId: _vm.selectSeekBeautyId },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: `消息图集`,
            visible: _vm.dialogMessageGalleryVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMessageGalleryVisible = $event
            },
          },
        },
        [
          _vm.dialogMessageGalleryVisible
            ? _c("SeekBeautyLogMessageGalleryList", {
                attrs: {
                  messageId: _vm.selectMessageId,
                  userId: _vm.sendUserId,
                },
                on: { changeMessageGallery: _vm.handleChangeMessageGallery },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: `常用方案`,
            visible: _vm.dialogCommonSchemeVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCommonSchemeVisible = $event
            },
          },
        },
        [
          _vm.dialogCommonSchemeVisible
            ? _c("commonSchemeList", {
                attrs: { symptom_id: _vm.dataModel.secondSymptomId },
                on: { selectSchemeSuccess: _vm.selectSchemeSuccess },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }