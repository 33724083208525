var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c(
          "el-form",
          {
            ref: "dataInfo",
            staticClass: "demo-ruleForm form-wrap",
            attrs: {
              model: _vm.dataInfo,
              "label-width": "100%",
              "label-position": "top",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "问诊类型",
                  prop: "consultationType",
                  "label-width": "120px",
                },
              },
              [
                _c("el-cascader", {
                  ref: "myCascader",
                  staticStyle: { width: "300px" },
                  attrs: {
                    options: _vm.consultationTypeList,
                    props: { value: "id", label: "text", children: "children" },
                    "collapse-tags": "",
                    clearable: "",
                    placeholder: "请选择问诊类型",
                  },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.cityValue,
                    callback: function ($$v) {
                      _vm.cityValue = $$v
                    },
                    expression: "cityValue",
                  },
                }),
              ],
              1
            ),
            _vm.dataInfo.list
              ? _c("h3", { staticClass: "m-b-10" }, [_vm._v("问卷题目")])
              : _vm._e(),
            _vm._l(_vm.dataInfo.list, function (item, index) {
              return _c(
                "el-form-item",
                {
                  key: item.id,
                  attrs: {
                    label: index + 1 + "." + item.questionTitle,
                    prop: "list." + index + ".selectData",
                    rules: {
                      required: true,
                      message: " 请选择答案",
                      trigger: "blur",
                    },
                  },
                },
                [
                  item.questionType == 1
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: item.selectData,
                              callback: function ($$v) {
                                _vm.$set(item, "selectData", $$v)
                              },
                              expression: "item.selectData",
                            },
                          },
                          _vm._l(item.list, function (subItem) {
                            return _c(
                              "el-radio",
                              {
                                key: subItem.id,
                                attrs: { label: subItem.questionTitle },
                              },
                              [_vm._v(_vm._s(subItem.questionTitle))]
                            )
                          }),
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: item.selectData,
                              callback: function ($$v) {
                                _vm.$set(item, "selectData", $$v)
                              },
                              expression: "item.selectData",
                            },
                          },
                          _vm._l(item.list, function (subItem) {
                            return _c(
                              "el-checkbox",
                              {
                                key: subItem.id,
                                attrs: { label: subItem.questionTitle },
                              },
                              [_vm._v(_vm._s(subItem.questionTitle))]
                            )
                          }),
                          1
                        ),
                      ],
                ],
                2
              )
            }),
            _c(
              "el-form-item",
              [
                _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.addBtnLoading,
                      disabled: _vm.addBtnLoading,
                    },
                    on: { click: _vm.clickAddOrEditConfirm },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }