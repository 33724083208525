<template>
    <div class="container">
        <div class="page-container">
            <el-form :model="dataInfo" ref="dataInfo" label-width="100%" label-position="top"
                class="demo-ruleForm form-wrap" :rules="rules">
                <!-- <el-form-item label="问诊类型" prop="symptomId">
                    <el-cascader ref="selectCity" :options="consultationTypeList"
                        :props="{ value: 'id', label: 'text', children: 'children' }" @change="selectRegionHandle"
                        placeholder="请选择问诊类型"></el-cascader>
                </el-form-item> -->
                <!-- :value="[this.dataInfo.firstSymptomId, this.dataInfo.symptomId]" -->
                <el-form-item label="问诊类型" prop="consultationType" label-width="120px">
                    <el-cascader ref="myCascader" v-model="cityValue" :options="consultationTypeList"
                        :props="{ value: 'id', label: 'text', children: 'children' }" collapse-tags clearable
                        @change="handleChange" style="width: 300px" placeholder="请选择问诊类型"></el-cascader>
                </el-form-item>
                <h3 class="m-b-10" v-if="dataInfo.list">问卷题目</h3>
                <el-form-item v-for="item, index in dataInfo.list" :key="item.id"
                    :label="index + 1 + '.' + item.questionTitle" :prop="'list.' + index + '.selectData'"
                    :rules="{ required: true, message: ' 请选择答案', trigger: 'blur' }">
                    <template v-if="item.questionType == 1">
                        <!-- 单选 -->
                        <el-radio-group v-model="item.selectData">
                            <el-radio v-for="subItem in item.list" :key="subItem.id" :label="subItem.questionTitle">{{
                                subItem.questionTitle
                            }}</el-radio>
                        </el-radio-group>
                    </template>
                    <template v-else>
                        <!-- 多选 -->
                        <el-checkbox-group v-model="item.selectData">
                            <el-checkbox v-for="subItem in item.list" :key="subItem.id"
                                :label="subItem.questionTitle">{{ subItem.questionTitle
                                }}</el-checkbox>
                        </el-checkbox-group>
                    </template>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clickAddOrEditCancel">取 消</el-button>
                    <el-button type="primary" :loading="addBtnLoading" :disabled="addBtnLoading"
                        @click="clickAddOrEditConfirm">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import beautyManager from '@/request/beautyManager.js'
export default {
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {

    },
    props: ['seekBeautyId', 'consultationTypeId'],
    data() {
        // 这里存放数据
        return {
            dataInfo: {
                firstId: '',
                secondId: '',
                consultationType: this.consultationTypeId > 0 ? this.consultationTypeId : '',
            },
            rules: {
                consultationType: [{ required: true, message: '请选择问诊类型', trigger: 'blur' }],
            },
            addBtnLoading: false,
            consultationType: this.consultationTypeId > 0 ? this.consultationTypeId : '',
            consultationTypeList: [],
            cityValue: [],
            methodCount: 0,

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()

        if (this.consultationTypeId > 0) {
            if (this.permission.getConsultationType) {
                this.getConsultationType()
            }
            if (this.permission.getQuestionnaire) {
                this.getQuestionnaireList()
            }
            this.methodCount = 0;
        } else {
            if (this.permission.getConsultationType) {
                this.getConsultationType()
            }
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        // 详情
        async getQuestionnaireAnswer() {
            try {
                let data = await beautyManager.getQuestionnaireAnswer({ beautyDiaryId: this.seekBeautyId, consultationTypeId: this.consultationTypeId })
                if (data.code == 200) {
                    if (data.data.consultationTypeIdTwo > 0) {
                        this.dataInfo.firstId = data.data.consultationTypeIdOne
                        this.dataInfo.secondId = data.data.consultationTypeIdTwo
                        this.dataInfo.consultationType = data.data.consultationTypeIdTwo
                        this.cityValue = [(this.dataInfo.firstId+''), this.dataInfo.secondId]
                    } else {
                        this.dataInfo.firstId = data.data.consultationTypeIdOne
                        this.dataInfo.consultationType = data.data.consultationTypeIdOne
                        this.cityValue = [this.dataInfo.firstId]
                    }
                    console.log('cityValue', this.cityValue);
                    this.dataInfo.list.forEach(obj => {
                        let newArr = data.data.list.filter(item => {
                            return item.questionId == obj.id
                        })
                        if (newArr.length) {
                            let item1 = newArr[0]
                            if (obj.questionType == 2) {
                                // obj.selectData = []
                                obj.selectData = item1.answer.split(',')
                            } else {
                                // obj.selectData = ''
                                obj.selectData = item1.answer
                            }
                        }
                    });
                    this.methodCount = 1;
                    console.log('detail-dataInfo1', this.dataInfo);
                }
            } catch (error) {
                //
                console.log('errorg=', error);
            }
        },
        //获取类型列表
        async getConsultationType() {
            try {
                let data = await beautyManager.getConsultationType()
                if (data.code == 200) {
                    this.consultationTypeList = this.toTree(data.data.children)
                }
            } catch (error) {
                //
            }
        },
        //处理数据
        toTree(data) {
            let result = []
            if (!Array.isArray(data)) {
                return result
            }
            data.forEach((item) => {
                if (!item.hasChildren) {
                    item.id = item.id*1
                    item.children = ''
                } else {
                    item.children.forEach((item2) => {
                        item2.id = item2.id*1
                        if (!item2.hasChildren) {
                            item2.children = ''
                            // item2.disabled = true
                        }
                    })
                }
            })
            return data
        },
        handleChange(value) {
            console.log(value);
            if (value.length == 1) {
                this.dataInfo.firstId = value[0];
                this.dataInfo.secondId = '';
                this.dataInfo.consultationType = value[0];
                if (this.permission.getQuestionnaire) {
                    this.getQuestionnaireList()
                }
            } else if (value.length == 2) {
                this.dataInfo.firstId = value[0];
                this.dataInfo.secondId = value[1];
                this.dataInfo.consultationType = value[1];
                if (this.permission.getQuestionnaire) {
                    this.getQuestionnaireList()
                }
            } else {
                // this.dataInfo.firstId = '';
                // this.dataInfo.secondId = '';
                // this.dataInfo.consultationType = '';
                this.dataInfo = {
                    firstId: '',
                    secondId: '',
                    consultationType: '',
                }
            }

        },
        // 获取问题
        async getQuestionnaireList() {
            try {
                let { code, data, message } = await beautyManager.getQuestionnaire({ consultationType: this.dataInfo.consultationType })
                if (code == 200 && data) {
                    data.list.forEach((obj, index) => {
                        if (obj.questionType == 2) {
                            obj.selectData = []
                        } else {
                            obj.selectData = ''
                        }
                    })
                    this.dataInfo = { ...this.dataInfo, ...data }
                    if (this.consultationTypeId > 0 && this.methodCount == 0) {
                        if (this.permission.getQuestionnaireAnswer) {
                            this.getQuestionnaireAnswer()
                        }
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        },
        clickAddOrEditCancel() {
            this.$emit('hideShow',1)
        },
        clickAddOrEditConfirm() {
            this.$refs.dataInfo.validate(async (valid) => {
                if (valid) {
                    //
                    var obj = {
                        answerRecordDOLists: []
                    }
                    // {"questionId":28,"beautyDiaryId":"50","templateId":13,"answer":"半年","questionName":"想多久解决问题","userId":1873,"isLabel":0}
                    obj.answerRecordDOLists = this.dataInfo.list.map(item => {
                        return {
                            questionId: item.id,
                            beautyDiaryId: this.seekBeautyId,
                            templateId: this.dataInfo.templateId,
                            answer: item.selectData.toString(),
                            questionName: item.questionTitle,
                        }
                    })
                    this.addBtnLoading = true
                    try {
                        let data = await beautyManager.submitQuestionnaire(obj.answerRecordDOLists)
                        if (data.code == 200) {
                            this.$message.success(data.message)
                            this.$emit('hideShow',2)
                        }
                    } catch (error) {
                        // 
                    } finally {
                        this.addBtnLoading = false
                    }

                } else {
                    return false;
                }
            });
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>