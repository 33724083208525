<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveSymptomGallery" @click="onAdd">添加
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'galleryId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="galleryId" label="编号" align="center" width="50">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="图片" min-width="70">
                    <template slot-scope="scope">
                        <el-image style="max-width: 40px; max-height: 40px" class="table-td-thumb"
                            :src="scope.row.thumbImg" :preview-src-list="[scope.row.bigImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="90px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateSymptomGallery">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeSymptomGallery">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 添加-->
        <el-dialog :title="`${editForm.galleryId > 0 ? '编辑' : '添加'}图片`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="150px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="症状图片" prop="galleryList">
                    <UploadImageNewOne :limit="editForm.galleryId > 0 ? 1 : 9" :imgList.sync="editForm.galleryList"
                        accept="image/*"></UploadImageNewOne>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog> 
    </div>
</template>
  
<script>
import { getList } from "@/tools/getList.js";
import beautyManager from "@/request/beautyManager.js";
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
export default {
    mixins: [getList],
    props: ["symptomId"],
    components: {
        UploadImageNewOne,
    },
    data() {
        return {
            requst: {
                module: beautyManager,
                url: "symptomGalleryList",
                addUrl: "saveSymptomGallery",
                updateUrl: "updateSymptomGallery",
            },
            query: {
                symptomId: this.symptomId,
            },
            //添加
            addOrEditDialog: false,
            selectType: 0,
            addOrEditBtnLoading: false,
            editForm: {
                symptomId: this.symptomId,
                galleryId: 0,
                galleryList: [],
            },
            addRules: {
                galleryList: [
                    { type: "array", required: true, message: "请上传症状图集", trigger: "blur" },
                ],
            },
        };
    },

    methods: {
        // 点击添加
        onAdd() {
            this.selectType = 0;
            this.addOrEditDialog = true;
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData();
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true;
                        for (let index = 0; index < this.editForm.galleryList.length; index++) {
                            const obj = this.editForm.galleryList[index];
                            if (this.editForm.galleryId) {
                                obj.galleryId = this.editForm.galleryId;
                                obj.symptomId = this.symptomId;
                            } else {
                                obj.symptomId = this.symptomId;
                            }
                        }

                        if (this.editForm.galleryId > 0) {
                            let data = await beautyManager.updateSymptomGallery(this.editForm.galleryList[0]);
                            if (data.code == 200) {
                                this.$message({
                                    message: data.message,
                                    type: "success",
                                });
                                this.refresCurrentPagehData();
                                this.resetAddOrEditData();
                            }
                        } else {
                            let data = await beautyManager.saveSymptomGallery({ symptomGalleryList: JSON.stringify(this.editForm.galleryList) });
                            if (data.code == 200) {
                                this.$message({
                                    message: data.message,
                                    type: "success",
                                });
                                this.refresCurrentPagehData();
                                this.resetAddOrEditData();
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.addOrEditBtnLoading = false;
                    }
                }
            });
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false;
            this.editForm.galleryList = [];
            this.editForm.galleryId = 0;
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm.galleryList = [row];
            this.editForm.galleryId = row.galleryId;
            this.selectType = 1;
            this.addOrEditDialog = true;
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await beautyManager.removeSymptomGallery({
                    galleryId: row.galleryId,
                });
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: "success",
                    });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>

</style>
  