var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            ref: "table",
            attrs: {
              data: _vm.tableData,
              border: "",
              stripe: "",
              "row-key": "galleryId",
              height: _vm.tableHeight,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "galleryId",
                label: "编号",
                align: "center",
                width: "50",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.$index + 1))]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "图片", "min-width": "70" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-image",
                        {
                          staticClass: "table-td-thumb",
                          staticStyle: {
                            "max-width": "40px",
                            "max-height": "40px",
                          },
                          attrs: {
                            src: scope.row.thumbImg,
                            "preview-src-list": [scope.row.bigImg],
                            fit: "cover",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }