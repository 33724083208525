<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveSymptom"
                        @click="addRegionClick({}, {parentId:0}, 1)">添加症状</el-button>
                </el-form-item>
            </el-form>
            <div class="regionContent">
                <el-tree :data="tableData" node-key="symptomId" :props="defaultProps" @node-expand="handleNodeExpand"
                    @node-collapse="handleNodeCollapse" :default-expanded-keys="defaultExpandIds">
                    <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseenter(data)"
                        @mouseleave="mouseleave(data)" style="width: 100%">
                        <span v-show="node.label != ''"> {{ node.label }} </span>
                        <span class="button" v-show="data.del && !showEdit[data.symptomId]">
                            <!-- 症状说明 -->
                            <el-button type="text" size="mini" @click.stop="() => rowContent(data)" v-show="data.parentId > 0">
                                症状说明
                            </el-button>
                            <!-- 症状图集 -->
                            <el-button type="text" size="mini" @click.stop="() => symptomGalleryHandle(data)"
                                v-show="data.parentId > 0 && permission.symptomGalleryList">
                                症状图集
                            </el-button>
                            <!-- 症状关系 -->
                            <el-button type="text" size="mini" @click.stop="() => symptomCompleteHandle(data)"
                                v-show="data.parentId > 0 && permission.symptomCompleteList">
                                症状关系
                            </el-button>

                            <!-- 添加同级 -->
                            <el-button type="text" size="mini" @click.stop="() => addRegionClick(node, data, 1)"
                                v-show="permission.saveSymptom">
                                添加同级
                            </el-button>
                            <!-- 添加子级 -->
                            <el-button type="text" size="mini" @click.stop="() => addRegionClick(node, data, 2)"
                                v-show="data.parentId == 0 && permission.saveSymptom">
                                添加子级
                            </el-button>
                            <!-- 编辑 -->
                            <el-button type="text" size="mini" @click.stop="() => editbankclassgetype(node, data)"
                                v-show="permission.updateSymptom">
                                修改
                            </el-button>
                            <!-- 删除 -->
                            <el-popconfirm title="您确定要删除么？" iconColor="red"
                                @confirm="() => removeknowledgetype(node, data)" v-show="permission.removeSymptom"
                                :ref="`popover-${data.symptomId}`">
                                <el-button slot="reference" type="text" size="mini" style="margin-left: 10px"
                                    @click.stop="closePopover">删除</el-button>
                            </el-popconfirm>
                        </span>
                    </span>
                </el-tree>
            </div>
        </div>

        <!-- 添加同级 -->
        <el-dialog :title="showTypeTitle" :visible.sync="addSameLevelRegion" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form :model="addRegion" :rules="addRules" ref="addRegion" label-width="120px" class="demo-ruleForm">
                <el-form-item label="症状名称" prop="symptomName">
                    <el-input size="medium" v-model="addRegion.symptomName" placeholder="请填写症状名称"></el-input>
                </el-form-item>
                <el-form-item label="二级症状名称" prop="secondLevelName" v-if="addRegion.parentId == 0 && showType==1">
                    <el-input size="medium" v-model="addRegion.secondLevelName" placeholder="请填写二级症状名称"></el-input>
                </el-form-item>
                <el-form-item label="症状说明" prop="symptomExplain" class="is-required" v-if="addRegion.parentId > 0 || showType==1">
                    <el-input size="medium" type="textarea" maxlength="255" v-model="addRegion.symptomExplain"
                        prefix-icon="el-icon-user" placeholder="请填写症状说明"></el-input>
                </el-form-item>
                <el-form-item label="是否显示">
                    <el-checkbox v-model="addRegion.isShow" :true-label="1" :false-label="0"></el-checkbox>
                </el-form-item>
                <el-form-item label="权重" prop="orderWeight">
                    <el-input type="number" v-model="addRegion.orderWeight" placeholder="请填写权重"></el-input>
                </el-form-item>
            </el-form>

            <!-- 取消确定 -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetAddOrEditData">取 消</el-button>
                <el-button size="medium" type="primary" :loading="addSameRegionLoading" @click="onAddSameRegionSubmit">确
                    定</el-button>
            </div>
        </el-dialog>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
        <!-- 症状图集 -->
        <el-dialog title="症状示例图集" :visible.sync="dialogLookGalleryVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <SymptomGalleryList :symptomId="selectSymptomId" v-if="dialogLookGalleryVisible" />
        </el-dialog>
        <!-- 症状关系 -->
        <el-dialog title="症状完善信息关系" :visible.sync="dialogSymptomCompleteVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <SymptomCompleteList :symptomId="selectSymptomId" v-if="dialogSymptomCompleteVisible" />
        </el-dialog>
    </div>
</template>
  
<script>
import { getList } from "@/tools/getList.js";
import beautyManager from "@/request/beautyManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import SymptomGalleryList from "@/components/page/seekBeauty/SymptomGalleryList.vue"
import SymptomCompleteList from "@/components/page/seekBeauty/SymptomCompleteList.vue"
export default {
    mixins: [getList],
    components: {
        ShowContent,
        SymptomGalleryList,
        SymptomCompleteList,
    },
    data() {
        return {
            defaultProps: {
                children: "children",
                label: "symptomName",
            },
            currendNode: {}, //记录的node
            currendData: {}, //用于data
            showEdit: [],

            //1,添加同级 2,添加子级 3修改
            showType: 0,
            //弹出的标题
            showTypeTitle: "",

            addSameLevelRegion: false,
            addSameRegionLoading: false,
            //添加地址的model
            addRegion: {
                symptomId: 0,
                symptomName: "",
                secondLevelName: "",
                symptomExplain: "",
                parentId: 0,
                isShow: 1,
                orderWeight: 9,
                children: [],
            },
            addRules: {
                symptomName: [{ required: true, message: "请填写症状名称", trigger: "blur" }],
                secondLevelName: [{ required: true, message: "请填写二级症状名称", trigger: "blur" }],
                symptomExplain: [{ required: true, message: "请填写症状说明", trigger: "blur" }],
                orderWeight: [{ required: true, message: "请输入权重", trigger: "blur" },],
            },
            defaultExpandIds: [],
            dialogSeeContent: false,
            rowInfo: {
                title: '',
                content: '',
            },
            //
            selectSymptomId: 0,
            dialogLookGalleryVisible: false,
            dialogSymptomCompleteVisible: false,
        };
    },
    created() {
        this.handlePermission();
        this.getSymptomList();
    },
    methods: {
        //获取城市列表
        async getSymptomList() {
            this.showLoading = true;
            // console.log(this.);
            try {
                let data = await beautyManager.symptomList();
                // this.tableData = [];
                if (data.code == 200) {
                    this.tableData = this.toTree(data.data);
                }
                this.showLoading = false;
            } catch (error) {
                this.showLoading = false;
            }
        },
        //处理数据
        toTree(data) {
            let result = [];
            if (!Array.isArray(data)) {
                return result;
            }
            data.forEach((item) => {
                item.children = [];
            });
            let map = {};
            data.forEach((item) => {
                map[item.symptomId] = item;
            });
            data.forEach((item) => {
                let parent = map[item.parentId];
                if (parent) {
                    (parent.children || (parent.children = [])).push(item);
                } else {
                    result.push(item);
                }
            });
            console.log("totree");
            return result;
        },

        //进入
        mouseenter(data) {
            this.$set(data, "del", true);
        },
        //离开
        mouseleave(data) {
            this.$set(data, "del", false);
        },
        resetAddOrEditData() {
            this.addRegion = {
                symptomId: 0,
                symptomName: "",
                secondLevelName: "",
                symptomExplain: "",
                parentId: 0,
                isShow: 1,
                orderWeight: 9,
                children: [],
            },
                this.currendNode = {};
            this.currendData = {};
            this.addSameLevelRegion = false;
        },

        // 添加同级/子级 type 1,同级 2.子级
        addRegionClick(node, data, type) {
            this.showType = type;
            if (type == 1) {
                this.showTypeTitle = "添加同级";
                this.addRegion.parentId = data.parentId;
            } else {
                this.showTypeTitle = "添加子级";
                this.addRegion.parentId = data.symptomId;
            }
            //记录要修改的对象
            this.currendNode = node;
            this.currendData = data;
            
            //把操作元素初始值
            this.addSameLevelRegion = true;
        },

        //修改
        editbankclassgetype(node, data) {
            this.showType = 3;
            this.showTypeTitle = "修改";
            this.currendData = data;
            //
            this.addRegion.children = data.children;
            this.addRegion.orderWeight = data.orderWeight;
            this.addRegion.isShow = data.isShow;
            this.addRegion.parentId = data.parentId;
            this.addRegion.symptomId = data.symptomId;
            this.addRegion.symptomName = data.symptomName;
            this.addRegion.symptomExplain = data.symptomExplain;
            this.addSameLevelRegion = true;
        },
        closePopover() {
            //每次只显示一个Popover弹窗 使用cancel()
            // 遍历所有的refs，判断索引名中是否有 'popover-' ;如果有，都使用cancel关掉
            for (const key in this.$refs) {
                if (key.indexOf("popover-") !== -1) {
                    if (typeof this.$refs[key] !== "undefined") {
                        this.$refs[key].cancel();
                    }
                }
            }
        },
        //删除
        async removeknowledgetype(node, dataNode) {
            try {
                let data = await beautyManager.removeSymptom({ symptomId: dataNode.symptomId });
                if (data.code == 200) {
                    if (data.code == 200) {
                        this.$message.success(data.message);
                        this.getSymptomList();
                        this.resetAddOrEditData();
                    }
                }
            } catch (error) {
                //
            }
        },

        //添加症状
        onAddSameRegionSubmit() {
            this.$refs.addRegion.validate(async (valid) => {
                if (valid) {
                    // 添加同级症状
                    if (this.showType == 1) {
                        this.addRegionInfo(
                            this.addRegion.symptomName,
                            this.addRegion.secondLevelName,
                            this.addRegion.symptomExplain,
                            this.addRegion.orderWeight,
                            this.addRegion.isShow,
                            this.currendData.parentId,
                        );
                    } else if (this.showType == 2) {
                        // 添加子级症状
                        this.addRegionInfo(
                            this.addRegion.symptomName,
                            this.addRegion.secondLevelName,
                            this.addRegion.symptomExplain,
                            this.addRegion.orderWeight,
                            this.addRegion.isShow,
                            this.currendData.symptomId,
                        );
                    } else {
                        this.editRegionInfo(
                            this.addRegion.symptomId,
                            this.addRegion.symptomName,
                            this.addRegion.symptomExplain,
                            this.addRegion.orderWeight,
                            this.addRegion.isShow,
                            this.addRegion.parentId,
                        );
                    }
                } else {
                    return false;
                }
            });
        },

        //添加症状
        async addRegionInfo(symptomName,secondLevelName, symptomExplain, orderWeight, isShow, parentId) {
            this.addSameRegionLoading = true;
            try {
                let data = await beautyManager.saveSymptom({
                    symptomName: symptomName,
                    secondLevelName: secondLevelName,
                    symptomExplain: symptomExplain,
                    orderWeight: orderWeight,
                    isShow: isShow,
                    parentId: parentId,
                });
                if (data.code == 200) {
                    this.$message.success(data.message);
                    this.getSymptomList();
                    this.resetAddOrEditData();
                }
                this.addSameRegionLoading = false;
            } catch (error) {
                this.addSameRegionLoading = false;
            }
        },
        //MARK:修改症状信息接口
        async editRegionInfo(symptomId, symptomName, symptomExplain, orderWeight, isShow, parentId) {
            this.addSameRegionLoading = true;
            try {
                let data = await beautyManager.updateSymptom({
                    symptomId: symptomId,
                    symptomName: symptomName,
                    symptomExplain: symptomExplain,
                    orderWeight: orderWeight,
                    isShow: isShow,
                    parentId: parentId,
                });
                if (data.code == 200) {
                    this.$message.success(data.message);
                    this.getSymptomList();
                    this.resetAddOrEditData();
                }
                this.addSameRegionLoading = false;
            } catch (error) {
                this.addSameRegionLoading = false;
            }
        },
        // 树节点展开
        handleNodeExpand(data) {
            // 保存当前展开的节点
            let flag = false;
            this.defaultExpandIds.some((item) => {
                if (item === data.symptomId) {
                    // 判断当前节点是否存在， 存在不做处理
                    flag = true;
                    return;
                }
            });
            if (!flag) {
                // 不存在则存到数组里
                this.defaultExpandIds.push(data.symptomId);
            }
        },
        // 树节点关闭
        handleNodeCollapse(data) {
            // 删除当前关闭的节点
            this.defaultExpandIds.some((item, i) => {
                if (item === data.symptomId) {
                    this.defaultExpandIds.splice(i, 1);
                }
            });
            // 这里主要针对多级树状结构，当关闭父节点时，递归删除父节点下的所有子节点
            this.removeChildrenIds(data);
        },
        // 删除树子节点
        removeChildrenIds(data) {
            const ts = this;
            //懒加载的时候想要删除子节点  得在加载下级数据时添加上data.children
            if (data.children) {
                data.children.forEach(function (item) {
                    const index = ts.defaultExpandIds.indexOf(item.symptomId);
                    if (index != -1) {
                        ts.defaultExpandIds.splice(index, 1);
                    }
                    ts.removeChildrenIds(item);
                });
            }
        },
        rowContent(row) {
            this.rowInfo.title = "症状说明";
            this.rowInfo.content = row.symptomExplain;
            this.dialogSeeContent = true;
        },
        //症状图集
        symptomGalleryHandle(row) {
            if (this.permission.symptomGalleryList) {
                this.selectSymptomId = row.symptomId;
                this.dialogLookGalleryVisible = true;
            } else {
                this.$message.error("您没有查看症状图集的权限");
            }
        },
        //症状关系
        symptomCompleteHandle(row) {
            if (this.permission.symptomGalleryList) {
                this.selectSymptomId = row.symptomId;
                this.dialogSymptomCompleteVisible = true;
            } else {
                this.$message.error("您没有查看症状关系的权限");
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
.button {
    margin-left: 100px;
}

.custom-tree-node {
    line-height: 40px;
}
</style>
  