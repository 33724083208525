<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <!-- <el-form-item>
                    <el-input type="text" placeholder="请输入完善信息名称" v-model="query.completeName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveSymptomComplete" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'symptomCompleteId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="symptomCompleteId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="completeName" label="完善信息名称" align="center"></el-table-column>
                <el-table-column prop="completeOptionInfoList" label="完善信息选项" align="center">
                    <template slot-scope="scope">
                        <a @click="showCompleteOptionHandle(scope.row)">
                            <el-link type="primary">查看</el-link>
                        </a>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="isShow" label="是否显示" width="90" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="orderWeight" label="权重值" width="90" align="center"></el-table-column> -->
                <el-table-column label="操作" width="110px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateSymptomComplete">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeSymptomComplete">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectStore">
                            <el-button icon="el-icon-circle-check" type="text" class="success"
                                @click="selectStoreHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看完善信息详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
        <!-- 完善信息选项 -->
        <el-dialog :title="`完善信息选项 `" :visible.sync="dialogCompleteOptionListVisible" :modal="false"
            :destroy-on-close="true" fullscreen class="absolute">
            <CompleteOptionList v-if="dialogCompleteOptionListVisible" :completeId="selectCompleteId" />
        </el-dialog>
        <!-- 添加完善信息 -->
        <el-dialog :title="`${editForm.symptomCompleteId ? '编辑' : '添加'}完善信息`" :visible.sync="addOrEditDialog"
            :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="完善信息ID" prop="completeId" v-if="editForm.symptomCompleteId > 0 "
                    class="is-required">
                    <el-input v-model="editForm.completeId" placeholder="请选择完善信息" disabled style="width: 400px">
                    </el-input>
                    <el-button style="margin-left: 20px" type="primary" plain @click="createIdChangeNew(1)">选择
                    </el-button>
                </el-form-item>
                <el-form-item label="完善信息IDs" prop="completeIds" v-else class="is-required">
                    <el-input v-model="editForm.completeIds" placeholder="请选择完善信息" disabled style="width: 400px">
                    </el-input>
                    <el-button style="margin-left: 20px" type="primary" plain @click="createIdChangeNew(2)">选择
                    </el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 选择完善信息 -->
        <el-dialog title="选择完善信息" :visible.sync="dialogCompleteListFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <CompleteList :key="'key' + currentKey" :completeSelectType="completeSelectType"
                @changeSelectCompleteIds="handleChangeSelectCompleteIds" v-if="dialogCompleteListFormVisible">
            </CompleteList>
        </el-dialog>

    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import beautyManager from "@/request/beautyManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import CompleteOptionList from "@/components/page/seekBeauty/CompleteOptionList.vue"
import CompleteList from "@/components/page/seekBeauty/CompleteList.vue"

export default {
    mixins: [getList],
    props: ["symptomId"],
    components: {
        ShowContent,
        CompleteOptionList,
        CompleteList,
    },
    data() {
        return {
            requst: {
                module: beautyManager,
                url: 'symptomCompleteList',
                addUrl: 'saveSymptomComplete',
                updateUrl: 'updateSymptomComplete',
            },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                symptomId: this.symptomId,
            },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                symptomCompleteId: 0,
                symptomId: this.symptomId,
                completeId: '',
                completeIds: '',
            },
            addRules: {
                completeId: [{ required: true, message: "请选择完善信息", trigger: "blur" }],
                completeIds: [{ required: true, message: "请选择完善信息", trigger: "blur" }],
            },
            dialogCompleteOptionListVisible: false,
            selectCompleteId: 0,
            //
            currentKey: 1,
            dialogCompleteListFormVisible: false,
            completeSelectType: 0,
        };
    },
    created() {

    },
    methods: {
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await this.requst.module[this.editForm.symptomCompleteId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                symptomCompleteId: 0,
                symptomId: this.symptomId,
                completeId: '',
                completeIds: '',
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm = {
                symptomCompleteId: row.symptomCompleteId,
                symptomId: this.symptomId,
                completeId: row.completeId,
                completeIds: '',
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await beautyManager.removeSymptomComplete({ symptomCompleteId: row.symptomCompleteId });
                if (code === 200) {
                    // this.$message.success(message);
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    // this.$message({ message: "删除成功", type: "success" });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        //选择
        selectStoreHandle(row) {
            this.$emit("changeSelectStore", row)
        },
        //完善信息选项
        showCompleteOptionHandle(row) {
            if (this.permission.completeOptionList) {
                this.selectCompleteId = row.completeId;
                this.dialogCompleteOptionListVisible = true;
            } else {
                this.$message.error("您没有查看完善信息选项的权限")
            }
        },
        // 1.编辑单选；2.添加多选
        createIdChangeNew(type) {
            this.completeSelectType = type;
            this.dialogCompleteListFormVisible = true;
        },
        handleChangeSelectCompleteIds(selectIds) {
            console.log(selectIds);
            this.dialogCompleteListFormVisible = false;
            if (this.completeSelectType == 1) {
                this.editForm.completeId = selectIds;
            } else {
                if (this.editForm.completeIds.length > 0) {
                    var oldArray = this.editForm.completeIds.split(",");
                    var selectArray = selectIds.split(",");
                    for (const obj of selectArray) {
                        if (!oldArray.includes(obj)) {
                            oldArray.push(obj);
                        }
                    }
                    this.editForm.completeIds = oldArray.join(",");
                } else {
                    this.editForm.completeIds = selectIds;
                }
            }

        },

    },
};
</script>

<style scoped>

</style>

