<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入用户名称" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入症状名称" v-model="query.symptomName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.isDeal" placeholder="是否处理" clearable>
            <el-option v-for="item in isDealList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'recordId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="recordId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="用户头像" width="90" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" style="max-width:40px;max-height:40px" :src="scope.row.headImg" :preview-src-list="[scope.row.headImg]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center">
        </el-table-column>
        <el-table-column prop="loginName" label="手机号" min-width="120" align="center">
        </el-table-column>
        <el-table-column label="症状" width="140" align="center">
          <template slot-scope="scope">
            {{scope.row.firstSymptomName}}-{{scope.row.secondSymptomName}}
          </template>
        </el-table-column>
        <el-table-column prop="detailedDescription" label="详细说明" width="100" align="center">
          <template slot-scope="scope">
            <span @click="showContentHandle(scope.row,1)">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="detailedDescription" label="咨询图集" width="100" align="center">
          <template slot-scope="scope">
            <span @click="showContentHandle(scope.row,2
            )">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="isDeal" label="是否处理" width="90" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isDeal?'el-icon-check':'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="dealContent" label="处理内容" width="100" align="center">
          <template slot-scope="scope">
            <span @click="showContentHandle(scope.row,3)" v-if="scope.row.isDeal == 1">
              <el-link type="primary">详情</el-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="dealTime" label="处理时间" width="140" align="center">
          <template slot-scope="scope">
            {{ scope.row.dealTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="140" align="center">
          <template slot-scope="scope">
            {{ scope.row.addTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="处理" placement="top" v-if="permission.handleConsultationRecordInfo && scope.row.isDeal == 0">
              <el-button class="success" type="text" icon="el-icon-document-checked" @click="clickDealWithInfo(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <show-content v-model='dialogSeeContent' :title='rowInfo.title' :content='rowInfo.content'></show-content>
    <!-- 投诉信息 -->
    <el-dialog title="咨询图集" :visible.sync="dialogLookGalleryVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <ConsultationGalleryList :recordId="lookGalleryModel.recordId" v-if="dialogLookGalleryVisible" />
    </el-dialog>

    <!-- 添加同级 -->
    <el-dialog title="处理" :visible.sync="dialogDealModelVisible" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form :model="dealModel" :rules="dealRules" ref="dealModel" label-width="120px" class="demo-ruleForm">
        <el-form-item label="处理内容" prop="dealContent">
          <el-input type="textarea" size="medium" v-model="dealModel.dealContent" placeholder="请填写处理内容"></el-input>
        </el-form-item>
      </el-form>
      <!-- 取消确定 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetAddOrEditData">取 消</el-button>
        <el-button size="medium" type="primary" :loading="dealModelLoading" @click="onDealModelSubmit">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import beautyManager from '@/request/beautyManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import ConsultationGalleryList from './ConsultationGalleryList.vue'

export default {
  mixins: [getList],
  components: {
    ShowContent,
    ConsultationGalleryList,
  },
  data() {
    return {
      requst: {
        module: beautyManager,
        url: 'consultationRecordInfoList',
        addUrl: '',
        updateUrl: '',
      },
      query: {
        symptomName: '',
        isDeal: '',
        nickName: '',
      },
      isDealList: [
        { value: 0, label: '否' },
        { value: 1, label: '是' },
      ],
      dialogSeeContent: false,
      rowInfo: {},
      //查看
      dialogLookGalleryVisible: false,
      lookGalleryModel: {
        recordId: 0,
      },
      //处理
      dealModelLoading: false,
      dialogDealModelVisible: false,
      dealModel: {
        recordId: 0,
        dealContent: '',
        userId:'',
      },
      dealRules: {
        dealContent: [
          { required: true, message: '请输入处理内容', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    showContentHandle(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = '详细说明'
          this.rowInfo.content = row.detailedDescription
          this.dialogSeeContent = true
          break
        case 2:
          this.lookGalleryModel.recordId = row.recordId
          this.dialogLookGalleryVisible = true
          break
        case 3:
          this.rowInfo.title = '处理内容'
          this.rowInfo.content = row.dealContent
          this.dialogSeeContent = true
          break

        default:
          break
      }
    },
    clickDealWithInfo(row) {
      this.dealModel.recordId = row.recordId
      this.dealModel.userId = row.userId
      this.dialogDealModelVisible = true
    },
    resetAddOrEditData() {
      this.dialogDealModelVisible = false
      this.dealModel.recordId = 0
      this.dealModel.dealContent = ''
      this.dealModel.userId = ''
    },
    onDealModelSubmit() {
      this.$refs.dealModel.validate(async (valid) => {
        if (valid) {
          //
          try {
            this.dealModelLoading = true
            let data = await beautyManager.handleConsultationRecordInfo(
              this.dealModel
            )
            if (data.code == 200) {
              this.$message.success(data.message)
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            //
          } finally {
            this.dealModelLoading = false
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>