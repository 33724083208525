import BaseModule from './utils/BaseModule'
import requestNew from './utils/requestNew.js'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }

    // ****************************** 求美日志 ******************************
    //求美日志列表
    seekBeautyLogList(param) {
        return this.get('web/seekBeautyLogInfo/list', param)
    }
    //求美日志详情
    seekBeautyLogInfo(param) {
        return this.get('web/seekBeautyLogInfo/info', param)
    }
    //求美日志新增
    saveSeekBeautyLog(param) {
        return this.post('web/seekBeautyLogInfo/save', param)
    }
    //设置求美日志为案例
    setCaseLog(param) {
        return this.post('web/seekBeautyLogInfo/setCase', param)
    }
    //修改求美日志案例信息
    updateSeekBeautyLog(param) {
        return this.post('web/seekBeautyLogInfo/update', param)
    }
    //求美日志删除
    removeSeekBeautyLog(param) {
        return this.post('web/seekBeautyLogInfo/remove', param)
    }


    //求美日志实拍照片
    logPhotoList(param) {
        return this.get('web/seekBeautyPhotoInfo/list', param)
    }
    //新增求美日志实拍照片
    saveLogPhoto(param) {
        return this.post('web/seekBeautyPhotoInfo/save', param)
    }
    //修改求美日志实拍照片
    updateLogPhoto(param) {
        return this.post('web/seekBeautyPhotoInfo/update', param)
    }
    //删除求美日志实拍照片
    removeLogPhoto(param) {
        return this.post('web/seekBeautyPhotoInfo/remove', param)
    }


    //求美日志消息
    logMessageList(param) {
        return this.get('web/seekBeautyLogMessageInfo/list', param)
    }
    //新增求美日志消息
    saveLogMessage(param) {
        return this.post('web/seekBeautyLogMessageInfo/save', param)
    }
    //修改求美日志消息
    updateLogMessage(param) {
        return this.post('web/seekBeautyLogMessageInfo/update', param)
    }
    //删除求美日志消息
    removeLogMessage(param) {
        return this.post('web/seekBeautyLogMessageInfo/remove', param)
    }
    //回复求美日志信息
    replySeekBeautyLogMessage(param) {
        return this.post('web/seekBeautyLogMessageInfo/replySeekBeautyLogMessage', param)
    }
    //修改求美日志信息
    updateReplySeekBeauty(param) {
        return this.post('web/seekBeautyLogMessageInfo/update', param)
    }
    //待回复任务列表
    seekBeautyLogInfoList(param) {
        return this.get('web/seekBeautyLogInfo/list', param)
    }
    //客服接待
    customerReception(param) {
        return this.post('web/seekBeautyLogMessageInfo/reception', param)
    }
    //不需要回复
    unwantedMessage(param) {
        return this.post('web/seekBeautyLogInfo/noReply', param)
    }
    //求助专家
    specialistHelp(param) {
        return this.post('web/seekBeautyLogInfo/seekHelp', param)
    }

    //求美日志消息图集
    logMessageGalleryList(param) {
        return this.get('web/seekBeautyLogMessageGalleryInfo/list', param)
    }
    //新增求美日志消息图集
    saveLogMessageGallery(param) {
        return this.post('web/seekBeautyLogMessageGalleryInfo/save', param)
    }
    //修改求美日志消息图集
    updateLogMessageGallery(param) {
        return this.post('web/seekBeautyLogMessageGalleryInfo/update', param)
    }
    //删除求美日志消息图集
    removeLogMessageGallery(param) {
        return this.post('web/seekBeautyLogMessageGalleryInfo/remove', param)
    }
    // Api皮肤检测
    getSkinDetection(param) {
        return this.post('web/seekBeautyLogInfo/getSkinDetection', param, { notShowMsg: true })
    }

    // ****************************** 完善信息 ******************************
    //完善信息列表
    completeList(param) {
        return this.get('web/completeInfo/list', param)
    }
    //完善信息新增
    saveComplete(param) {
        return this.post('web/completeInfo/save', param)
    }
    //完善信息修改
    updateComplete(param) {
        return this.post('web/completeInfo/update', param)
    }
    //完善信息删除
    removeComplete(param) {
        return this.post('web/completeInfo/remove', param)
    }

    //完善信息选项列表
    completeOptionList(param) {
        return this.get('web/completeOptionInfo/list', param)
    }
    //完善信息选项新增
    saveCompleteOption(param) {
        return this.post('web/completeOptionInfo/save', param)
    }
    //完善信息选项修改
    updateCompleteOption(param) {
        return this.post('web/completeOptionInfo/update', param)
    }
    //完善信息选项删除
    removeCompleteOption(param) {
        return this.post('web/completeOptionInfo/remove', param)
    }


    // ****************************** 症状信息 ******************************
    //症状信息列表
    symptomList(param) {
        return this.get('web/symptomInfo/list', param)
    }
    //症状信息新增
    saveSymptom(param) {
        return this.post('web/symptomInfo/save', param)
    }
    //症状信息修改
    updateSymptom(param) {
        return this.post('web/symptomInfo/update', param)
    }
    //症状信息删除
    removeSymptom(param) {
        return this.post('web/symptomInfo/remove', param)
    }

    //症状图集列表
    symptomGalleryList(param) {
        return this.get('web/symptomGalleryInfo/list', param)
    }
    //症状图集新增
    saveSymptomGallery(param) {
        return this.post('web/symptomGalleryInfo/save', param)
    }
    //症状图集修改
    updateSymptomGallery(param) {
        return this.post('web/symptomGalleryInfo/update', param)
    }
    //症状图集删除
    removeSymptomGallery(param) {
        return this.post('web/symptomGalleryInfo/remove', param)
    }

    //症状完善信息关系
    symptomCompleteList(param) {
        return this.get('web/symptomCompleteInfo/list', param)
    }
    //症状完善信息关系新增
    saveSymptomComplete(param) {
        return this.post('web/symptomCompleteInfo/save', param)
    }
    //症状完善信息关系修改
    updateSymptomComplete(param) {
        return this.post('web/symptomCompleteInfo/update', param)
    }
    //症状完善信息关系删除
    removeSymptomComplete(param) {
        return this.post('web/symptomCompleteInfo/remove', param)
    }


    // ****************************** 常用方案 ******************************
    //常用方案列表
    commonSchemeInfoList(param) {
        return this.get('web/commonSchemeInfo/list', param)
    }
    //常用方案新增
    saveCommonSchemeInfo(param) {
        return this.post('web/commonSchemeInfo/save', param)
    }
    //修改常用方案
    updateCommonSchemeInfo(param) {
        return this.post('web/commonSchemeInfo/update', param)
    }
    //常用方案删除
    removeCommonSchemeInfo(param) {
        return this.post('web/commonSchemeInfo/remove', param)
    }


    //转诊记录
    referralRecordInfoList(param) {
        return this.get('web/referralRecordInfo/list', param)
    }

    //咨询记录
    consultationRecordInfoList(param) {
        return this.get('web/consultationRecordInfo/list', param)
    }

    //咨询记录图集
    consultationRecordGalleryInfoList(param) {
        return this.get('web/consultationRecordGalleryInfo/list', param)
    }

    //咨询处理
    handleConsultationRecordInfo(param) {
        return this.post('web/consultationRecordInfo/handle', param)
    }



    // ****************************** 入店记录 ******************************
    // 入店记录列表
    seekBeautyLogNewList(param) {
        return this.get('/web/seekBeautyLogNewInfo/list', param)
    }

    //设置或取消 是否为案例
    async updateSeekBeautyLogNewInfo(param) {
        return await requestNew({
            url: "/web/seekBeautyLogNewInfo/update",
            method: 'post',
            data: param
        })
    }

    // 查看解决方案
    getSeekBeautyLogDetail(param) {
        return this.get('pc/expert/getSeekBeautyLogDetail', param)
    }
    // 查看报告
    getReportDetail(param) {
        return this.get('/web/seekBeautyLogNewInfo/getReportDetail', param)
    }


    // ****************************** 设置2.0V案例 ******************************
    // 查看已答问卷内容
    getQuestionnaireAnswer(param) {
        return this.get('/web/seekBeautyLogNewInfo/getAnswer', param)
    }
    // 提交问卷 
    async submitQuestionnaire(param) {
        return await requestNew({
            url: "/web/seekBeautyLogNewInfo/submitQuestionnaire",
            method: 'post',
            data: param
        })
    }
    // 开始问诊（获取问诊类型） 
    getConsultationType(param) {
        return this.get('/web/seekBeautyLogNewInfo/getConsultationType', param)
    }
    // 开始问诊（获取问卷） 
    getQuestionnaire(param) {
        return this.get('/web/seekBeautyLogNewInfo/getQuestionnaire', param)
    }
    


}

export default new BaseManager()