<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <template v-if="!symptom_id">
          <el-form-item>
            <el-input type="text" placeholder="请输入症状名称" v-model="query.symptomName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain @click="refreshData">搜索</el-button>
          </el-form-item>
        </template>
        <el-form-item  v-if="permission.saveCommonSchemeInfo">
          <el-button type="primary" plain @click="onAdd">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'schemeId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="schemeId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="schemeContent" label="方案内容" align="center"></el-table-column>
        <el-table-column prop="symptomName" label="症状名称" align="center" width="120">
          <template slot-scope="scope">{{scope.row.firstLevelName}} - {{scope.row.symptomName}}</template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否显示" align="center" width="100">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="orderWeight" label="权重值" align="center" width="100"></el-table-column>
        <el-table-column label="操作" width="110px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateCommonSchemeInfo">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeCommonSchemeInfo">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger"></el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectCommonSchemeInfo">
              <el-button class="success" type="text" icon="el-icon-circle-check" @click="clickSelectInfo(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
    </div>
    <!-- 查看圈子类别详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加圈子类别 -->
    <el-dialog :title="`${editForm.schemeId ? '编辑' : '添加'}圈子类别`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="方案内容" prop="schemeContent">
          <el-input type="textarea" size="medium" v-model="editForm.schemeContent" placeholder="请输入方案内容"></el-input>
        </el-form-item>
        <el-form-item label="症状信息" prop="symptomId">
          <el-cascader ref="selectCity" :options="symptomList" :value="[this.editForm.firstSymptomId, this.editForm.symptomId]" :props="{ value: 'symptomId', label: 'symptomName', children: 'children' }" @change="selectRegionHandle" placeholder="请选择症状信息"></el-cascader>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow">
          <el-checkbox v-model="editForm.isShow" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input type="number" v-model="editForm.orderWeight" placeholder="请填写权重"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import beautyManager from '@/request/beautyManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import { number } from 'echarts'
export default {
  mixins: [getList],
  components: {
    ShowContent,
  },
  props: {
    symptom_id: {  //症状ID
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      requst: {
        module: beautyManager,
        url: 'commonSchemeInfoList',
        addUrl: 'saveCommonSchemeInfo',
        updateUrl: 'updateCommonSchemeInfo',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        symptomName: '', //搜索词
        symptomId: this.symptom_id,   //症状ID
      },
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        schemeId: 0,
        firstSymptomId: '',
        symptomId: '',
        schemeContent: '',
        isShow: 1,
        orderWeight: 9,
      },
      symptomValueList: [],
      addRules: {
        schemeContent: [
          { required: true, message: '请输入方案内容', trigger: 'blur' },
        ],
        symptomId: [{ required: true, message: '请输入症状', trigger: 'blur' }],
        orderWeight: [
          { required: true, message: '请输入权重', trigger: 'blur' },
        ],
      },
      symptomList: [],
    }
  },
  created () {
    this.handlePermission()
    if (this.permission.selectSymptomList) {
      this.getSymptomList()
    }
  },
  methods: {
    //获取城市列表
    async getSymptomList () {
      try {
        let data = await beautyManager.symptomList()
        if (data.code == 200) {
          this.symptomList = this.toTree(data.data)
        }
      } catch (error) {
        //
      }
    },
    //处理数据
    toTree (data) {
      let result = []
      if (!Array.isArray(data)) {
        return result
      }
      data.forEach((item) => {
        item.children = ''
      })
      let map = {}
      data.forEach((item) => {
        map[item.symptomId] = item
      })
      data.forEach((item) => {
        let parent = map[item.parentId]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
        } else {
          result.push(item)
        }
      })
      result.forEach((item) => {
        if (item.children == '') {
          item.disabled = true
        }
      })
      return result
    },

    // 点击添加
    onAdd () {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel () {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm () {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[
              this.editForm.schemeId > 0
                ? this.requst.updateUrl
                : this.requst.addUrl
            ](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success',
              })
              this.refresCurrentPagehData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },

    // 清除添加数据
    resetAddOrEditData () {
      this.addOrEditDialog = false
      this.editForm = {
        schemeId: 0,
        firstSymptomId: '',
        symptomId: '',
        schemeContent: '',
        isShow: 1,
        orderWeight: 9,
      }
    },
    // 点击编辑信息
    async clickEditInfo (row) {
      this.editForm = {
        schemeId: row.schemeId,
        firstSymptomId: row.firstLevelId,
        symptomId: row.symptomId,
        schemeContent: row.schemeContent,
        isShow: row.isShow,
        orderWeight: row.orderWeight,
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin (row, index) {
      try {
        let { code, message } = await beautyManager.removeCommonSchemeInfo({
          schemeId: row.schemeId,
        })
        if (code === 200) {
          this.$message({
            message: message,
            type: 'success',
          })
          this.delRefresh(index)
        }
      } catch (error) {
        console.log(error)
      }
    },
    selectRegionHandle (row) {
      if (row.length == 2) {
        this.editForm.firstSymptomId = row[0]
        this.editForm.symptomId = row[1]
      }
    },
    // 选择
    clickSelectInfo(row){
      this.$emit('selectSchemeSuccess',row)
    },
  },
}
</script>

<style scoped>
</style>
